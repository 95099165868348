import {translate} from "./translate"
import {itemType} from "./types";

export const hotSideDataBIOMASS: itemType[] = [
    {
        id: 'hot_medium',
        title: translate('Medium'),
        options: [], // from BE
        default_value: "FLUE_GAS",
        measure: null,
    },
    // {
    //     id: 'hot_ingredients_of_medium',
    //     title: translate('Ingredients of medium'),
    //     options: [],
    //     unit: '%',
    //     default_value: '',
    // },
    {
        id: 'hot_boiler_gas_input',
        title: translate('Boiler gas input'),
        measure: 'Power',
        options: [],
        default_value: '',
        withSlider: true,
    },
    {
        id: 'hot_mass_flow_of_medium',
        title: translate('Mass flow of medium'),
        measure: 'Flow',
        options: [],
        default_value: '',
        withSlider: true,
    },
    {
        id: 'hot_inlet_temperature_of_medium',
        title: translate('Inlet temperature  of medium *'),
        measure: 'Temperature',
        options: [],
        default_value: '',
    },
    {
        id: 'hot_outlet_temperature_of_medium',
        title: translate('Outlet temperature of medium *'),
        measure: 'Temperature',
        options: [],
        default_value: '',
    },
    {
        id: 'hot_medium_humidity_level',
        title: translate('Humidity level *'),
        measure: null,
        options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        unit: '',
        default_value: '1',
    },
    {
        id: 'hot_allowable_pressure_drop_of_medium',
        title: translate('Allowable pressure drop of medium'),
        measure: 'Pressure',
        options: [],
        default_value: '300',
    },
    {
        id: 'hot_fouling',
        title: translate('Fouling'),
        measure: 'Fouling',
        options: [],
        default_value: '0',
    },
    // {
    //     id: 'hot_ingredients_of_fouling',
    //     title: translate('Ingredients of fouling'),
    //     options: [],
    //     unit: '%',
    //     default_value: '',
    // },
    {
        id: 'hot_lambda',
        title: translate('Lambda'),
        measure: null,
        options: [],
        default_value: '1.3',
    },
    {
        id: 'minimum_oversize',
        title: translate('Minimum oversize'),
        measure: 'Oversize',
        options: [],
        default_value: '5',
    },

]

export const hotSideData = hotSideDataBIOMASS.filter(item => item.id !== 'hot_medium_humidity_level');

export const coldSideData: itemType[] = [
    {
        id: 'cold_medium',
        title: translate('Medium'),
        options: [], // ['Water', 'Glycol']
        default_value: 'WATER',
        measure: null,
    },
    // {
    //     id: 'cold_ingredients_of_medium',
    //     title: translate('Ingredients of medium'),
    //     options: [],
    //     unit: '%',
    //     default_value: '',
    // },
    {
        id: 'cold_inlet_temperature_of_medium',
        title: translate('Inlet temperature of medium *'),
        measure: 'Temperature',
        options: [],
        default_value: '',
    },
    {
        id: 'cold_outlet_temperature_of_medium',
        title: translate('Outlet temperature of medium *'),
        measure: 'Temperature',
        options: [],
        default_value: '',
    },
    {
        id: 'cold_mass_flow_of_medium',
        title: translate('Mass flow of medium'),
        measure: 'Flow',
        options: [],
        default_value: '',
        withSlider: true,
    },
    {
        id: 'cold_allowable_pressure_drop_of_medium',
        title: translate('Allowable pressure drop of medium'),
        measure: 'ColdDropPressure',
        options: [],
        default_value: '30',
    },
    {
        id: 'cold_fouling',
        title: translate('Fouling'),
        measure: 'Fouling',
        options: [],
        default_value: '0',
    },
    // {
    //     id: 'cold_ingredients_of_fouling',
    //     title: translate('Ingredients of fouling'),
    //     options: [],
    //     unit: '%',
    //     default_value: '',
    // },
]

export const sidesData = [...hotSideData, ...coldSideData];
export const sidesDataBiomass = [...hotSideDataBIOMASS, ...coldSideData];
