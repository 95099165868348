// tabs structure
import accesories_none from "../assets/images/accesories/accesories_none.png"
import accesories_flange from '../assets/images/accesories/accesories_flange.png'
import accesories_no_flange from '../assets/images/accesories/accesories_no-flange.png'

import inlet_no_flange from '../assets/images/elineBig/inlet_noflange.png'
import inlet_flange from '../assets/images/elineBig/inlet_flange.png'

import outlet_no_flange from '../assets/images/elineBig/outlet_noflange.png'
import outlet_flange from '../assets/images/elineBig/outlet_flange.png'


/*   I think we should save it for future, here are the images for water connection  */

// import in_left_out_left from '../assets/images/elineBig/in-left_out-left.png'
// import in_left_out_right from '../assets/images/elineBig/in-left_out-right.png'
// import in_right_out_left from '../assets/images/elineBig/in-right_out-left.png'
// import in_right_out_right from '../assets/images/elineBig/in-right_out-right.png'


export const tabs = {
    tab1: {
        id: 1,
        title: 'Specification',
        rows: [
            { key: 'boiler_capacity', name: 'Boiler capacity:', value: '-' },
            { key: 'sensible_heat', name: 'Sensible heat:', value: '-' },
            { key: 'latent_heat', name: 'Latent heat:', value: '-' },
            { key: 'heat_load', name: 'Heat capacity:', value: '-' },
            { key: 'LMTD', name: 'LMTD:', value: '-' },
            { key: 'min_oversizing', name: 'Min. oversizing:', value: '-' },
        ],
    },
    tab2: {
        id: 2,
        title: 'Properties',
        rows: [
            { key: 'medium', name: 'Medium:', value: '-' },
            { key: 'reference_temperature', name: 'Reference temperature:', value: '-' },
            { key: 'specific_heat', name: 'Specific heat:', value: '-' },
            { key: 'density', name: 'Densitiy:', value: '-' },
            { key: 'thermal_conductivity', name: 'Thermal conductivity:', value: '-' },
            { key: 'dynamic_viscosity', name: 'Dynamic viscosity:', value: '-' },
            { key: 'kinematic_viscosity', name: 'Kinematic viscosity:', value: '-' },
            { key: 'prandtl_number', name: 'Prandtl number:', value: '-' },
        ],
    },
    tab3: {
        id: 3,
        title: 'Configuration',
        eline_small: [
            {
                id: 'eline_small_inlet',
                name: 'Flue inlet',
                rows: [
                    { id: 'inlet_left_without_flange', name: 'Inlet left without flange' }, // no need to include 'img'
                    { id: 'inlet_left_with_flange', name: 'Inlet left with flange' }, // because it is rendered in
                    { id: 'inlet_right_without_flange', name: 'Inlet right without flange' }, // separate component
                    { id: 'inlet_right_with_flange', name: 'Inlet right with flange' }, // and is imported from assets
                ],
            },
            // {
            //     id: 'eline_small_water',
            //     name: 'Water connection',
            //     rows: [
            //         { id: 'front_without_flange', name: 'Inlet and outlet front without flange' },
            //     ],
            // },
            {
                id: 'eline_small_outlet',
                name: 'Flue outlet',
                rows: [
                    { id: 'outlet_left_without_flange', name: 'Outlet left without flange' },
                    { id: 'outlet_left_with_flange', name: 'Outlet left with flange' },
                    { id: 'outlet_right_without_flange', name: 'Outlet right without flange' },
                    { id: 'outlet_right_with_flange', name: 'Outlet right with flange' },
                ],
            },
            {
                id: 'eline_small_accesories',
                name: 'Accessories',
                rows: [
                    { id: 'accesories_none', name: 'No accessory (standard groove)', img: accesories_none},
                    { id: 'accesories_astm', name: 'Flange ASTM A182 ASME B16.5', img: accesories_flange },
                    { id: 'accesories_pn', name: 'Flange PN-EN 1092-1 DN65', img: accesories_flange },
                    { id: 'accesories_npt', name: 'NPT Thread', img: accesories_no_flange },
                    { id: 'accesories_bspp', name: 'BSPP Thread', img: accesories_no_flange },
                ],
            },
        ],
        eline_big: [
            {
                id: 'eline_big_inlet',
                name: 'Flue inlet',
                rows: [
                    { id: 'inlet_without_flange', name: 'Inlet without flange', img: inlet_no_flange },
                    { id: 'inlet_with_flange', name: 'Inlet with flange', img: inlet_flange },
                ],
            },
            {
                id: 'eline_big_outlet',
                name: 'Flue outlet',
                rows: [
                    { id: 'outlet_without_flange', name: 'Outlet without flange', img: outlet_no_flange },
                    { id: 'outlet_with_flange', name: 'Outlet with flange', img: outlet_flange },
                ],
            },
            // {
            //     id: 'eline_big_water',
            //     name: 'Water connection',
            //     rows: [
            //         { id: 'water_left_left', name: 'Inlet - left, outlet - left', img: in_left_out_left },
            //         { id: 'water_left_right', name: 'Inlet - left, outlet - right', img: in_left_out_right },
            //         { id: 'water_right_left', name: 'Inlet - right, outlet - left', img: in_right_out_left },
            //         { id: 'water_right_right', name: 'Inlet - right, outlet - right', img: in_right_out_right },
            //     ],
            // },
            {
                id: 'eline_big_accesories',
                name: 'Accessories',
                rows: [
                    { id: 'accesories_none', name: 'No accessory (standard groove)', img: accesories_none },
                    { id: 'accesories_astm', name: 'Flange ASTM A182 ASME B16.5', img: accesories_flange },
                    { id: 'accesories_pn', name: 'Flange PN-EN 1092-1 DN65', img: accesories_flange },
                    { id: 'accesories_npt', name: 'NPT Thread', img: accesories_no_flange },
                    { id: 'accesories_bspp', name: 'BSPP Thread', img: accesories_no_flange },
                ],
            },
        ],
    },
}

export const defaultConfigurationElineSmall = {
    [tabs.tab3.eline_small[0].id]: tabs.tab3.eline_small[0].rows[0], // eline_small_inlet: 'Inlet left without flange'
    // [tabs.tab3.eline_small[1].id]: tabs.tab3.eline_small[1].rows[0], // eline_small_water: 'Inlet and outlet front without flange'
    [tabs.tab3.eline_small[1].id]: tabs.tab3.eline_small[1].rows[0], // eline_small_outlet: 'Outlet left without flange'
    [tabs.tab3.eline_small[2].id]: tabs.tab3.eline_small[2].rows[0], // eline_small_accesories: 'Flange ASTM A182 ASME B16.5'
}


export const defaultConfigurationElineBig = {
    [tabs.tab3.eline_big[0].id]: tabs.tab3.eline_big[0].rows[0], // eline_big_inlet: 'Inlet without flange'
    [tabs.tab3.eline_big[1].id]: tabs.tab3.eline_big[1].rows[0], // eline_big_outlet: 'Outlet without flange'
    // [tabs.tab3.eline_big[2].id]: tabs.tab3.eline_big[2].rows[0], // eline_big_water: 'Inlet - left, outlet - left'
    [tabs.tab3.eline_big[2].id]: tabs.tab3.eline_big[2].rows[0], // eline_big_accesories: 'Flange ASTM A182 ASME B16.5'
}
