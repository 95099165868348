import {InputValuesType} from "../types";

type ObjParameter = InputValuesType | null | undefined;

export const isObjectsCompare = (obj1: ObjParameter, obj2: ObjParameter): boolean => {
    if (obj1 === undefined || obj1 === null || obj2 === undefined || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}
