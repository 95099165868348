import {Font, StyleSheet} from "@react-pdf/renderer";
import AeonikBlack from "../../assets/fonts/AeonikPro-Black.otf";
import AeonikMedium from "../../assets/fonts/AeonikPro-Medium.otf";
import AeonikBold from "../../assets/fonts/AeonikPro-Bold.otf";

Font.register({
    family: 'AeonikBlack',
    format: "truetype",
    src: AeonikBlack
});

Font.register({
    family: 'AeonikMedium',
    format: "truetype",
    src: AeonikMedium
});

Font.register({
    family: 'AeonikBold',
    format: "truetype",
    src: AeonikBold
});

export const styles = StyleSheet.create({
    page: {
        fontSize: 10,
        flexDirection: "column",
        fontFamily: 'AeonikMedium',
        paddingVertical: 20,
        paddingHorizontal: 45,
    },
    documentHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    headerTitle: {
        fontFamily: 'AeonikBlack',
        fontSize: 18
    },
    logo: {
        width: 65,
        height: 20
    },
    customerInfo: {
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    customerInfo_title: {
        width: 70,
        marginBottom: 2,
        marginRight: 3
    },
    customerInfo_value: {
        width: 150,
    },
    customerInfo_info: {
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    sheetTitle: {
        fontFamily: 'AeonikBold',
        fontSize: 12,
        textAlign: "center",
        marginTop: 15,
        marginBottom: 8
    },
    sheetContainer: {
        marginBottom: 20
    },
    sheetRow: {
        flexDirection: "row",
    },
    sheetRow_title: {
        width: '40%',
        paddingHorizontal: 5,
        paddingVertical: 3,
    },
    sheetRow_value: {
        width: '20%',
        textAlign: "center",
        paddingHorizontal: 5,
        paddingVertical: 3
    },
    sheetRow_unit: {
        width: '10%',
        paddingVertical: 3,
        textAlign: "left"
    },
    footer: {
      marginTop: 'auto',
      flexDirection: "row",
      justifyContent: "flex-end",
      fontSize: 8
    },
    footer_block: {
      marginLeft: 30,
    },
});
