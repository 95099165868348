import { useEffect, useState} from "react";
import {defaultUnits} from "../constants";
import {MeasureType, MeasureUnitsType} from "../types";
import {checkLocalStorage} from "../helpers/checkLocalStorage";
import api from "../../api";

export const UseMeasures = () => {
    const prevUnits = checkLocalStorage('Units');

    const [measureUnits, setMeasureUnits] = useState<MeasureUnitsType>()
    const [units, setUnits] = useState(prevUnits || defaultUnits )

    const getUnits = () => {
        api.getUnits()
            .then(res => {
                const {flow, fouling, power, pressure, temperature} = res.data;
                const newMeasures = {
                    'Power': power,
                    'Flow': flow,
                    'Temperature': temperature,
                    'Pressure': pressure,
                    'ColdDropPressure': pressure,
                    'Fouling': fouling,
                    'Oversize': ['%']
                }
                setMeasureUnits(newMeasures)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => localStorage.setItem('Units', JSON.stringify(units)), [units])

    const handleChangeMeasuring = (type: MeasureType, value: string) => {
        const newUnits = {...units, [type]: value}
        setUnits(newUnits)
        localStorage.setItem('Units', JSON.stringify(newUnits))
    }

    useEffect(() => getUnits(), [])

    return {units, measureUnits, handleChangeMeasuring}
}
