import {useEffect, useState} from "react";
import {useAppContext} from "../../context/AppContext";

export const UsePreloader = () => {
    const { limitations } = useAppContext()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (limitations) setLoading(false)
        }, 2350);
        return () => clearTimeout(timer);
    }, [limitations]);

    return loading;
}
