import {useCallback, useLayoutEffect, useState} from "react";
import api from "../../api";
import {toast} from "react-toastify";
import {LimitationSchema} from "../types";

export const UseLimitations = () => {

    const [limitations, setLimitations] = useState<LimitationSchema>();

    const getLimitations = useCallback(() => {
        api.getLimitations()
            .then(res => setLimitations(res.data))
            .catch(err => toast.error(err.message))
    }, [])

    useLayoutEffect(() => getLimitations(), [getLimitations]);

    return limitations as LimitationSchema;
}
