import styles from "../Results/Results.module.css";
import {translate} from "../../utils/translate";
import React from "react";

export const TabSpecificationRow = ({title, value}: { title: string, value: string }) => {
    return (
        <div>
            <p className={styles.results_tab_specification_container_bottom_text}>{translate(title)}</p>
            <p className={styles.results_tab_specification_container_bottom_text}>{value || '-'}</p>
        </div>
    )
}
