import Select, {SingleValue} from "react-select";
import {InputValuesType, MeasureType, MeasureUnitsType, SelectOptionProps, StoredUnitsType} from '../../utils/types';
import styles from "../../styles/Home.module.css";
import {Control, useController, UseFormTrigger} from "react-hook-form";
import {useEffect} from "react";

type UnitProps = {
    id: string,
    measure: MeasureType,
    units: StoredUnitsType,
    measureUnits: MeasureUnitsType,
    handleChangeMeasuring: (type: MeasureType, value: string) => void,
    control: Control,
    trigger: UseFormTrigger<InputValuesType>
};

const Unit = ({
                  measure,
                  id,
                  handleChangeMeasuring,
                  units,
                  measureUnits,
                  control, trigger
              }: UnitProps) => {

    const selectOptions: SelectOptionProps[] = measureUnits[measure].map(item => ({
        value: item,
        label: item
    }))

    const handleGetValue = (value: string) => selectOptions.find(elem => elem.value === value);

    const {field: {onChange, value}, ...rest} = useController({
        name: `${id}_unit`,
        control: control,
        defaultValue: units[measure]
    })

    useEffect(() => {
        onChange(units[measure])
    }, [units])

    return (selectOptions.length === 1)
            ? <p className={styles.home_input_unit}>{value}</p>
            : <Select
                {...rest}
                options={selectOptions}
                value={handleGetValue(value)}
                classNamePrefix='unit'
                className='unit'
                onChange={(newValue: SingleValue<SelectOptionProps>) => {
                    handleChangeMeasuring(measure, newValue!.value)
                    onChange(newValue!.value)
                    trigger()
                }}
            />
};

export default Unit;
