import React, {createContext, useContext, useState} from "react";
import { useLocalStorage } from "../utils/hooks/useLocalStorage";
import {UseLimitations} from "../utils/hooks/UseLimitations";
import {LimitationSchema} from "../utils/types";

type UserInfoType = {
    userToken: string;
    userEmail: string;
    logUserIn: (csrf: string) => void;
    logUserOut: () => void;
    rememberEmail: (email: string) => void;
    waitingForCode: boolean;
    toggleWaitingForCode: (flag: boolean) => void;
}

export interface AppContextInterface {
    userInfo: UserInfoType | null;
    limitations: LimitationSchema;
}

export const AppContext = createContext<AppContextInterface | null>(null);

export const AppContextProvider = ({ children } : {children: React.ReactNode}) => {

    const limitations = UseLimitations();

    const [userToken, setUserToken] = useLocalStorage("user", '');
    const [userEmail, setUserEmail] = useLocalStorage("email", '');
    const [waitingForCode, setWaitingForCode] = useState(false);

    const logUserIn = (csrf: string) => {
        setUserToken(csrf)
    }

    const rememberEmail = (email: string) => {
        setUserEmail(email)
    }

    const logUserOut =() => {
        setUserToken('');
        setUserEmail('')
    }

    const toggleWaitingForCode = (flag: boolean) => {
        setWaitingForCode(flag)
    }

    return <AppContext.Provider value={
        {
            userInfo: {
                userToken,
                userEmail,
                logUserIn,
                logUserOut,
                rememberEmail,
                waitingForCode,
                toggleWaitingForCode
            },
            limitations,
        }}>
          {children}
      </AppContext.Provider>
};


export const useAppContext = () => {
  return useContext(AppContext) as AppContextInterface;
};
