import {useAppContext} from "../../context/AppContext";
import api from "../../api";
import {toast} from "react-toastify";
import styles from "../Results/Results.module.css";
import {FiDownload} from "react-icons/fi";
import {translate} from "../../utils/translate";
import React from "react";

interface LinkForFileProps {
    url: string,
    title: string
}

export const LinkForFile = ({url, title}: LinkForFileProps) => {
    const {userInfo} = useAppContext();

    const handleFileDownload = (url: string) => {
        api.downloadFile(url, userInfo?.userToken as string)
            .catch(err => toast.error(err.message))
    }

    return (
        <button
            onClick={() => handleFileDownload(url)}
            className={styles.results_tab_specification_download}
        >
            <FiDownload/>
            {translate(title)}
        </button>
    )
};
