import React from 'react';
import "../../styles/common.css"
import styles from "../Results/Results.module.css";
import {tabs} from "../../utils/dataConfigurations";
import {translate} from "../../utils/translate";
import {InputValuesType, ResultType} from "../../utils/types";
import {useModalConfig} from "../../utils/hooks/useModalConfig";
import ModalWrapper from "../Modals/ModalWrapper";
import RequireLoginModal from "../Modals/RequireLoginModal";
import {UseResultTabs} from "../../utils/hooks/UseResultTabs";
import {SpecificationSolution} from "../SpecificationSolution/SpecificationSolution";

interface SpecificationProps {
    results: ResultType,
    inputValues: InputValuesType,
    setChosenTab: (v: string) => void
}

const Specification = ({results, inputValues, setChosenTab}: SpecificationProps) => {

    const {ref, isShown, setIsShown, isModalClosing, closeSoftly} = useModalConfig(false);

    const setConfigTab = () => setChosenTab('tab3')

    const handleSignIn = () => setIsShown(true)

    const { isSolutionFound, calculatedSolutionFound, noSolution, onlyCalculations } = UseResultTabs(results.solution);

    return (
        <>
            <div className={styles.results_tab_container}>

                {/* RESULT: CALCULATIONS */}
                {onlyCalculations
                    && tabs.tab1.rows.map(row => {
                        return (
                            <div key={row.key} className={styles.results_tab_row}>
                                <p className={styles.results_tab_desc}>{translate(row.name)}</p>
                                {/* @ts-ignore */ }
                                <p className={styles.results_tab_value}>{results.solution[row.key] || row.value}</p>
                            </div>
                        )
                    })}


                {/* RESULT: SOLUTION WITH HEAT EXCHANGER */}
                {isSolutionFound &&
                    <SpecificationSolution
                        results={results}
                        inputValues={inputValues}
                        goToConfig={setConfigTab}
                        onSignIn={handleSignIn}
                    />
                }


                {/* RESULT: WITH CALCULATIONS, NO SOLUTION */}
                {calculatedSolutionFound &&
                    <>
                        <p className={styles.results_no_result}>{translate('Heat exchanger not found. Please contact us to find a solution.')}</p>
                        <button className="est_button" onClick={setConfigTab}>
                            {translate('CONTACT AIC TEAM')}
                        </button>
                    </>
                }


                {/* RESULT: NO SOLUTION, NO CALCULATIONS */}
                {noSolution &&
                    <p className={styles.results_no_result}>{translate('Insufficient data entered to match an economizer. Please fill all required fields correctly')}</p>
                }

            </div>

            <ModalWrapper isClosing={isModalClosing} isOpen={isShown}>
                <RequireLoginModal reference={ref} closeModal={closeSoftly}/>
            </ModalWrapper>
        </>
    );
};

export default Specification;
