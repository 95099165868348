import React from "react";

export const Spinner = () => {
    return (
        <div
            className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-white rounded-full mt-6 relative left-[50%]"
            role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
        </div>
    );
};
