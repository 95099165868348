export function validateNumber(evt: any, isFloat: boolean) {
    var e = evt || window.event;
    var key = e.keyCode || e.which;

    if (!e.shiftKey && !e.altKey && !e.ctrlKey &&

        // numbers   
        key >= 48 && key <= 57 ||

        // Numeric keypad
        key >= 96 && key <= 105 ||

        // Backspace and Tab (if Enter key pressed => preventDefault)
        key == 8 || key == 9 ||

        // Home and End
        key == 35 || key == 36 ||

        // left and right arrows
        key == 37 || key == 39 ||

        // Del and Ins
        key == 46 || key == 45) {
        // input is VALID
    }

    // comma (not work in input type number) or period(dot)
    else if (isFloat && (key == 188 || key == 190)) {
        // input is VALID
    }

    else {
        // input is INVALID
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
    }
}
