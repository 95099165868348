import React, {useEffect, useRef, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import {UseMeasures} from "./utils/hooks/UseMeasures";
import {
    HotMediums,
    InputValuesType,
    MeasureUnitsType,
    ResultType
} from "./utils/types";
import api from "./api";
import {UseGSAP} from "./utils/hooks/UseGSAP";
import {useAppContext} from "./context/AppContext";
import {SearchInterface} from "./components/SearchInterface/SearchInterface";
import {useForm} from "react-hook-form";
import {ResultInterface} from "./components/ResultInterface/ResultInterface";
import {yupResolver} from "@hookform/resolvers/yup";
import {setSchema} from "./utils/validationSchema/searchValidation";
import {defaultFormValues, defaultResults, sliderLabels} from "./utils/constants";
import {isObjectsCompare} from "./utils/helpers/compareObjects";
import {isEmptyObject} from "./utils/helpers/IsEmptyObject";

const App = () => {
  const { userInfo, limitations } = useAppContext();
  const { measureUnits, units, handleChangeMeasuring } = UseMeasures();

    const {
        register, control, setValue, reset, watch, getValues, trigger,
        formState: { errors, isValid, isValidating }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: defaultFormValues,
        // @ts-ignore
        resolver: yupResolver(setSchema(limitations))
    })

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const watchHotMedium = watch('hot_medium');
  const watchSliders = watch(sliderLabels);
  const watchFields = watch();

  const [inputValues, setInputValues] = useState<InputValuesType>();
  const [isSliderTouched, setIsSliderTouched] = useState(false);

    // ---------------------- RESULTS ----------------------

    const [isResultViewOpen, setIsResultViewOpen] = useState(false); // only for mobile, desktop always open
    const [results, setResults] = useState<ResultType>(defaultResults);

    useEffect(() => {
        if (!isValidating && isValid && isEmptyObject({...errors}) && !isObjectsCompare(inputValues, getValues())) {
            setIsFormSubmitting(true)
            callBackendsForSolutions(getValues());
        }
    }, [watchFields]);

    useEffect(() => {
       if (!isValid) setResults(defaultResults)
    }, [isValid]);

  // init settings
  useEffect(() => checkUserLoggedIn(), []);

  const handleTouchSlider = () => setIsSliderTouched(true);

  const checkUserLoggedIn = () => {
    api.isUserLoggedIn(userInfo?.userToken!)
        .then(res => {
          userInfo?.logUserIn(res.data.csrf)  // refresh cookie and set new token
        })
        .catch(err => userInfo?.logUserOut()) // log user out if cookie expired)
  }

  function callBackendsForSolutions(values: InputValuesType) {
    setInputValues(values);
    api.findSolution(values)
        .then(res => {
          setResults(res.data); // solutions(results) arr
          setIsSliderTouched(false);
          setIsFormSubmitting(false)
        })
        .catch(err => {
          console.log('err: ', err);
          toast.error(err.message);
          setResults(defaultResults);
          setIsFormSubmitting(false);
        })
  }

  const setInitKeysAndDefaultValues = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset(defaultFormValues);
    setResults(defaultResults);
  };

  const propsToInputs = {
    register,
    control,
    trigger,
    setValue,
    watchSliders,
    errors,
    units,
    isSliderTouched,
    inputCalculatedInBackend: results.form,
    handleTouchSlider,
    measureUnits: measureUnits as MeasureUnitsType,
    handleChangeMeasuring,
  };

  /* Animations Green Sock */

  const mainLogoRef = useRef(null);
  const loginButton = useRef(null);
  const leftSide = useRef(null);

  UseGSAP({
    logo1: mainLogoRef,
    logo2: loginButton,
    form: leftSide,
  });

  // ---------------------- TSX ----------------------

  return (

      <div className="bg-gradient-dark" data-testid="app-component">
        <div className="mx-auto max-w-[1600px] px-4 min-h-screen flex flex-col">
          <Header mainLogoRef={mainLogoRef} loginButtonRef={loginButton} />

          <main className="flex mt-16 items-stretch justify-center">

            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={true}
            />

            <SearchInterface
                reference={leftSide}
                setInitKeysAndDefaultValues={setInitKeysAndDefaultValues}
                hotMedium={watchHotMedium as HotMediums}
                propsToInputs={propsToInputs}
            />

            <ResultInterface
                results={results}
                isFormSubmitting={isFormSubmitting}
                inputValues={inputValues as InputValuesType}
                isResultViewOpen={isResultViewOpen}
                setIsResultViewOpen={setIsResultViewOpen}
                units={units}
            />
          </main>
          <Footer />
        </div>
      </div>
  );
};

export default App;
