import {InputValuesType, ResultType} from "../../utils/types";
import {Page, Text, View, Document, Image} from '@react-pdf/renderer';
//logo
import aicLogo from "../../assets/img.png"
import {styles} from "./styles";
import {UseProvidedData} from "../../utils/hooks/UseProvidedData";

interface PDFDocumentProps {
    result: ResultType,
    userData: InputValuesType,
    customer: string
}

export const PDFDocument = ({ result, userData, customer }: PDFDocumentProps) => {

    const {projectValues, projectData, exchangerSelectionTable, physicalProps} = UseProvidedData({result, userData})

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.documentHeader}>
                    <Text style={styles.headerTitle}>HEAT EXCHANGERS CALCULATION SHEET</Text>
                    <Image src={aicLogo} style={styles.logo}/>
                </View>

                <View style={styles.customerInfo}>
                    <View>
                        <View style={styles.customerInfo_info}>
                            <Text style={styles.customerInfo_title}>CUSTOMER:</Text>
                            <Text style={styles.customerInfo_value}>{customer}</Text>
                        </View>
                        <View style={styles.customerInfo_info}>
                            <Text style={styles.customerInfo_title}>PROJECT:</Text>
                            <Text style={styles.customerInfo_value}>ECO</Text>
                        </View>
                    </View>

                    <View>
                        <View style={styles.customerInfo_info}>
                            <Text style={styles.customerInfo_title}>DATE:</Text>
                            <Text style={styles.customerInfo_value}>{new Date().toLocaleDateString()}</Text>
                        </View>
                        <View style={styles.customerInfo_info}>
                            <Text style={styles.customerInfo_title}>PREPARED BY:</Text>
                            <Text style={styles.customerInfo_value}>est.myaic.com</Text>
                        </View>
                    </View>
                </View>

                <Text style={styles.sheetTitle}>PROJECT DATA SHEET</Text>
                <View style={styles.sheetContainer}>
                    {projectData.map(el => {
                        return (
                            <View style={styles.sheetRow} key={el.title}>
                                <Text style={styles.sheetRow_title}>{el.title}</Text>
                                <Text style={styles.sheetRow_value}>{el.value}</Text>
                            </View>
                        )
                    })}
                </View>

                <View style={styles.sheetContainer}>
                    <View style={[styles.sheetRow, {marginBottom: 5}]}>
                        <View style={{width: '40%'}}/>
                        <Text style={[styles.sheetTitle, {width: '30%'}]}>Hot Side - Shell</Text>
                        <Text style={[styles.sheetTitle, {width: '30%'}]}>Cold Side - Tubes</Text>
                    </View>

                    {projectValues.map((el, idx) => {
                        if (!el) return <View style={{marginBottom: 5}} key={idx}/>
                        return (
                            <View style={styles.sheetRow} key={idx}>
                                <Text style={styles.sheetRow_title}>{el.title}</Text>
                                <Text style={styles.sheetRow_value}>{el.hotValue}</Text>
                                <Text style={styles.sheetRow_unit}>{el.hotUnit}</Text>
                                <Text style={styles.sheetRow_value}>{el.coldValue}</Text>
                                <Text style={styles.sheetRow_unit}>{el.coldUnit}</Text>
                            </View>
                        )
                    })}
                </View>

                <Text style={styles.sheetTitle}>HEAT EXCHANGER SELECTION</Text>
                <View style={[styles.sheetContainer, {marginVertical: 10}]}>
                    {exchangerSelectionTable.map((el, idx) => {
                        return (
                            <View style={[styles.sheetRow, idx === 0 ? {height: 20} : {}]}>
                                <Text style={styles.sheetRow_value}>{el.exNumber}</Text>
                                <Text style={styles.sheetRow_value}>{el.oversize}</Text>
                                <Text style={styles.sheetRow_value}>{el.dpHotSide}</Text>
                                <Text style={styles.sheetRow_value}>{el.dpColdSide}</Text>
                                <Text style={styles.sheetRow_value}>{el.HTA}</Text>
                            </View>
                        )
                    })}
                </View>

                <Text style={styles.sheetTitle}>PHYSICAL PROPERTIES</Text>
                <View style={[styles.sheetRow, {marginBottom: 5}]}>
                    <View style={{width: '40%'}}/>
                    <Text style={[styles.sheetTitle, {width: '30%'}]}>Hot Side - Shell</Text>
                    <Text style={[styles.sheetTitle, {width: '30%'}]}>Cold Side - Tubes</Text>
                </View>
                <View style={styles.sheetContainer}>
                    {physicalProps.map(el => {
                        if (!el) return <View style={{marginBottom: 5}}/>
                        return (
                            <View style={styles.sheetRow} key={el.title + el.hotValue}>
                                <Text style={styles.sheetRow_title}>{el.title}</Text>
                                <Text style={[styles.sheetRow_value, {width: '30%'}]}>{el.hotValue}</Text>
                                <Text style={[styles.sheetRow_value, {width: '30%'}]}>{el.coldValue}</Text>
                            </View>
                        )
                    })}
                </View>

                <View style={styles.footer}>
                    <View style={styles.footer_block}>
                        <Text style={{marginBottom: 5}}>AIC S.A.</Text>
                        <Text style={{marginBottom: 5}}>ul. Rdestowa 41</Text>
                        <Text>81-577 Poland</Text>
                    </View>
                    <View style={styles.footer_block}>
                        <Text style={{marginBottom: 5}}>+48 58 785 61 61</Text>
                        <Text style={{marginBottom: 5}}>info@myaic.com</Text>
                        <Text>www.myaic.com</Text>
                    </View>
                    <View style={styles.footer_block}>
                        <Text style={{marginBottom: 5}}>KRS 0000456510</Text>
                        <Text style={{marginBottom: 5}}>NIP 5891936466</Text>
                        <Text>REGON 220592964</Text>
                    </View>
                </View>

            </Page>
        </Document>
    );
};
