import {SolutionType} from "../types";

export const UseResultTabs = (results: SolutionType) => {

    const isSolutionFound = results.heat_exchanger_size && results.heat_exchanger_size !== '-';
    const onlyCalculations = results.boiler_capacity && results.boiler_capacity !== '-';
    const calculatedSolutionFound = results.boiler_capacity && results.boiler_capacity !== '-' && (!results.heat_exchanger_size || results.heat_exchanger_size === '-');
    const noSolution = (!results.boiler_capacity || results.boiler_capacity === '-') && (!results.heat_exchanger_size || results.heat_exchanger_size === '-');

    return { isSolutionFound, calculatedSolutionFound, noSolution, onlyCalculations }
}
