import styles from "../Results/Results.module.css";
import {translate} from "../../utils/translate";
import React from "react";
import {useAppContext} from "../../context/AppContext";
import {InputValuesType, ResultType} from "../../utils/types";
import {Files} from "./Files";
import {TabSpecificationRow} from "./TabSpecificationRow";
import {VscSignIn} from "react-icons/vsc";

interface SpecificationSolutionProps {
    results: ResultType,
    inputValues: InputValuesType,
    goToConfig: () => void,
    onSignIn: () => void
}

export const SpecificationSolution = ({ results, inputValues, goToConfig, onSignIn }: SpecificationSolutionProps) => {
    const { oversize, heat_exchanger_selection, heat_transfer_area, dp_fluegas_side_pa, dp_water_side_kpa, heat_load } = results.solution;
    const specArray = [
        {title: 'Oversize', value: oversize},
        {title: 'HTA', value: heat_transfer_area},
        {title: 'ΔP hot side', value: dp_fluegas_side_pa},
        {title: 'ΔP cold side', value: dp_water_side_kpa},
    ]

    const { userInfo } = useAppContext()

    return (
        <div className={styles.results_tab_specification_container}>
            <p className={styles.results_tab_specification_title}>{translate('Heat exchanger selection')}</p>
            <p className={styles.results_tab_specification_title2}>{heat_exchanger_selection}</p>
            <div className={styles.results_tab_specification_oversize}>
                <p className={styles.results_tab_specification_oversize_text}>{heat_load || '-'}</p>
            </div>
            <div className={styles.results_tab_specification_container_bottom}>
                {specArray.map((row, idx) => <TabSpecificationRow key={idx} {...row} />)}
            </div>

            <div className={styles.results_tab_specification_file}>
                {userInfo?.userToken
                    ? <Files solution={results} providedValues={inputValues} customer={userInfo.userEmail}/>
                    : <button
                        onClick={onSignIn}
                        className={styles.results_tab_specification_login}
                    >
                        <VscSignIn/>
                        {translate('SIGN IN TO GET ADDITIONAL FILES')}
                    </button>
                }
            </div>

            <button
                className="est_button lg:mt-auto mt-6"
                onClick={goToConfig}
            >
                {translate('GET A QUOTATION')}
            </button>
        </div>
    );
};
