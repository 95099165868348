import React, {useEffect, useRef, useState} from "react";


export const useModalConfig = (initVisibility: boolean) => {
    const [isShown, setIsShown] = useState(initVisibility);
    const [isModalClosing, setIsModalClosing] = useState(false);


    const ref: React.RefObject<HTMLDivElement> = useRef(null);

    const handleClickOutside = (evt: any) => {
        if (ref.current && !ref.current.contains(evt.target)) {
            closeSoftly()
        }
    }

    const closeSoftly = () => {
        setIsModalClosing(true)
        const timeout = setTimeout(() => {
            setIsShown(false)
            setIsModalClosing(false)
        }, 290)  // timeout should be little less than animation duration of closing modal (see modal.module.css)
        return () => clearTimeout(timeout)
    }


    useEffect(() => {
     document.addEventListener('click', handleClickOutside, true)
     return () => {
         document.removeEventListener('click', handleClickOutside, true)
     }
    }, [])

    useEffect(() => {
        if (isShown) document.body.style.overflow = "hidden";
        if (!isShown) document.body.style.overflow = 'unset';
    }, [isShown]);

    return { ref, isShown, setIsShown, isModalClosing, closeSoftly }
}
