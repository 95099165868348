import gsap from "gsap";
import React, {useLayoutEffect, useMemo} from "react";
import TweenVars = gsap.TweenVars;

type RefKeys = 'logo1' | 'logo2' | 'form';

type AnimatedElem = {
    element: React.RefObject<HTMLDivElement>,
    from: TweenVars,
    to: TweenVars
}

export const UseGSAP = (refs: Record<RefKeys, React.RefObject<HTMLDivElement>>) => {

    const refsWithAnimations = useMemo(() =>
            Object.values(refs).map((el, inx) =>
                inx === 2
                    ? {element: el, from: {x: -150}, to: {x: 0}}
                    : {element: el, from: {y: -150}, to: {y: 0}}
            )
        , [])

    const onMounting = (elem: AnimatedElem) => {
        const {element, to, from} = elem;
        gsap.fromTo(element.current,
            {...from, opacity: 0},
            {...to, opacity: 1}
        )
    }

    useLayoutEffect(() => {
        refsWithAnimations.forEach(elem => elem && onMounting(elem as AnimatedElem))
    }, [])

    return;
};
