import "./styles.css"
import {UsePreloader} from "../../utils/hooks/UsePreloader";

export const Preloader = ({ children }: { children: JSX.Element }) => {
    const loading = UsePreloader();

    return loading ? (
            <div className='preloader'>
                <svg xmlns="http://www.w3.org/2000/svg" width="165" height="35" viewBox="0 0 165 35" fill="none">
                    <path
                        d="M34.3172 0.653656H42.7455V3.62484H37.6152V7.27941H42.0126V10.2011H37.6152V14.0834H42.9535V17.0348H34.3172V0.653656Z"
                        fill="white" className="svg-elem-1"></path>
                    <path
                        d="M53.8479 13.3902L54.7194 16.0345C54.0162 16.599 52.6792 17.3418 50.9064 17.3418C47.0934 17.3418 44.3797 14.6777 44.3797 10.9538C44.3797 7.22989 47.1627 4.4964 50.8866 4.4964C52.6891 4.4964 53.9568 5.19958 54.7491 5.80372L53.838 8.51741C53.3031 8.02221 52.3623 7.34874 50.9856 7.34874C48.9058 7.34874 47.6183 8.96309 47.6183 10.934C47.6183 13.0138 49.0741 14.4895 51.1045 14.4895C52.3425 14.4895 53.3328 13.8358 53.8677 13.3902H53.8479Z"
                        fill="white" className="svg-elem-2"></path>
                    <path
                        d="M62.2266 4.4964C65.9703 4.4964 68.8325 7.2596 68.8325 10.9538C68.8325 14.648 66.0693 17.3418 62.2266 17.3418C58.3839 17.3418 55.6504 14.648 55.6504 10.9538C55.6504 7.2596 58.5027 4.4964 62.2266 4.4964ZM62.2266 14.4895C64.1678 14.4895 65.5939 13.0336 65.5939 10.9538C65.5939 8.87395 64.1678 7.34874 62.2266 7.34874C60.2854 7.34874 58.8791 8.84424 58.8791 10.9538C58.8791 13.0633 60.3052 14.4895 62.2266 14.4895Z"
                        fill="white" className="svg-elem-3"></path>
                    <path
                        d="M81.4106 9.97329V17.0447H78.1819V9.59694C78.1819 8.2401 77.241 7.39826 76.1417 7.39826C74.8046 7.39826 73.9628 8.37875 73.9628 9.69598V17.0447H70.7341V4.80342H73.8935V6.22959C74.4085 5.38776 75.5771 4.4964 77.1717 4.4964C80.6579 4.4964 81.4106 7.37845 81.4106 9.97329Z"
                        fill="white" className="svg-elem-4"></path>
                    <path
                        d="M89.7992 4.4964C93.5429 4.4964 96.4052 7.2596 96.4052 10.9538C96.4052 14.648 93.642 17.3418 89.7992 17.3418C85.9565 17.3418 83.223 14.648 83.223 10.9538C83.223 7.2596 86.0753 4.4964 89.7992 4.4964ZM89.7992 14.4895C91.7404 14.4895 93.1666 13.0336 93.1666 10.9538C93.1666 8.87395 91.7404 7.34874 89.7992 7.34874C87.858 7.34874 86.4517 8.84424 86.4517 10.9538C86.4517 13.0633 87.8779 14.4895 89.7992 14.4895Z"
                        fill="white" className="svg-elem-5"></path>
                    <path
                        d="M115.787 17.0447H112.559V9.71579C112.559 8.2599 111.836 7.39826 110.637 7.39826C109.439 7.39826 108.646 8.2599 108.646 9.73559V17.0348H105.418V9.58704C105.418 8.21038 104.715 7.38836 103.516 7.38836C102.318 7.38836 101.526 8.25 101.526 9.70588V17.0348H98.2968V4.80342H101.456V6.22959C101.902 5.38776 102.981 4.4964 104.635 4.4964C106.2 4.4964 107.349 5.19958 107.983 6.31873C108.706 5.19958 109.974 4.4964 111.608 4.4964C114.391 4.4964 115.777 6.34844 115.777 8.96309V17.0348L115.787 17.0447Z"
                        fill="white" className="svg-elem-6"></path>
                    <path
                        d="M119.907 0C120.987 0 121.799 0.792317 121.799 1.85204C121.799 2.91176 120.977 3.70408 119.907 3.70408C118.838 3.70408 118.035 2.91176 118.035 1.85204C118.035 0.792317 118.857 0 119.907 0ZM118.313 4.80342H121.541V17.0447H118.313V4.80342Z"
                        fill="white" className="svg-elem-7"></path>
                    <path
                        d="M127.414 14.3508H133.01V17.0447H123.413V15.054L128.94 7.4973H123.582V4.80342H132.941V6.81393L127.414 14.3508Z"
                        fill="white" className="svg-elem-8"></path>
                    <path
                        d="M140.082 4.4964C143.875 4.4964 146.024 7.02191 146.024 11.0231C146.024 11.2608 146.024 11.4886 146.004 11.7263H137.388C137.457 13.3604 138.764 14.539 140.567 14.539C142.231 14.539 143.191 13.5585 143.538 13.0435L145.667 14.6579C145.202 15.5195 143.558 17.3517 140.517 17.3517C136.586 17.3517 134.08 14.6381 134.08 10.9637C134.08 7.28932 136.586 4.5063 140.072 4.5063L140.082 4.4964ZM142.746 9.62665C142.676 8.13115 141.597 7.14076 140.121 7.14076C138.646 7.14076 137.616 8.22029 137.477 9.62665H142.746Z"
                        fill="white" className="svg-elem-9"></path>
                    <path
                        d="M154.512 7.77461C152.105 7.77461 151.214 9.27011 151.214 11.7065V17.0447H147.985V4.80342H151.144V6.58613C151.679 5.36795 152.897 4.5063 154.512 4.5063V7.78451V7.77461Z"
                        fill="white" className="svg-elem-10"></path>
                    <path
                        d="M155.443 15.0738L157.334 13.3902C157.948 14.0933 158.76 14.7272 160.147 14.7272C161.058 14.7272 161.643 14.331 161.643 13.6279C161.643 11.756 155.859 12.479 155.859 8.13115C155.859 5.86314 157.8 4.5063 160.306 4.5063C162.623 4.5063 163.911 5.72449 164.495 6.68517L162.366 8.27971C161.989 7.81422 161.316 7.11104 160.167 7.11104C159.414 7.11104 158.899 7.48739 158.899 8.09154C158.899 10.0327 164.703 9.10174 164.703 13.4793C164.703 15.8661 162.643 17.3616 159.998 17.3616C157.592 17.3616 156.086 16.1435 155.433 15.0936L155.443 15.0738Z"
                        fill="white" className="svg-elem-11"></path>
                    <path
                        d="M18.5699 6.97238L12.8652 1.24789L7.16056 6.97238C3.89226 10.2506 0 15.1927 0 20.1347C0 27.226 5.77401 33 12.8652 33C19.9565 33 25.7305 27.226 25.7305 20.1347C25.7305 15.1927 21.8382 10.2506 18.5798 6.97238H18.5699ZM12.8652 30.3853C6.72479 30.3853 1.72329 25.3838 1.72329 19.2434C1.72329 14.8757 5.38776 10.2803 8.45798 7.20018L12.8652 2.7731L17.2824 7.20018C20.3526 10.2803 24.0072 14.8658 24.0072 19.2434C24.0072 25.3838 19.0057 30.3853 12.8652 30.3853Z"
                        fill="white" className="svg-elem-12"></path>
                    <path
                        d="M18.5006 19.0255C18.3223 20.1248 17.5003 20.5309 17.4706 19.9664C17.7281 15.7275 16.0741 13.8655 13.7962 11.6272C15.4799 13.4397 14.539 16.6486 13.2812 15.7176C8.56692 12.3007 12.994 7.73499 12.994 7.73499C12.994 7.73499 8.73529 10.0327 10.4586 14.5786C12.172 19.1344 10.4586 19.7881 9.61674 19.8277C8.77491 19.8673 8.28961 18.5798 8.7551 17.0051C7.66566 17.9658 6.14045 21.4025 7.31902 23.9775C8.19057 26.1861 10.3496 27.7509 12.8652 27.7509C16.1633 27.7509 18.8373 25.0768 18.8373 21.7788C18.8373 21.7788 18.8373 19.9169 18.5105 19.0156L18.5006 19.0255Z"
                        fill="white" className="svg-elem-13"></path>
                    <path
                        d="M34.5846 30.2764C34.8025 30.9103 35.268 31.5738 36.4367 31.5738C37.3479 31.5738 38.051 31.0093 38.051 30.0684C38.051 27.8598 34.3469 28.4739 34.3469 26.0177C34.3469 25.0174 35.1096 24.1756 36.407 24.1756C37.7044 24.1756 38.2788 25.0372 38.467 25.5225L38.0015 25.7008C37.8133 25.2947 37.3478 24.6807 36.407 24.6807C35.4166 24.6807 34.9016 25.2947 34.9016 26.0276C34.9016 27.9985 38.6156 27.5627 38.6156 30.0387C38.6156 31.1678 37.7737 32.0888 36.4268 32.0888C35.0798 32.0888 34.3668 31.2371 34.1093 30.4547L34.5846 30.2764Z"
                        fill="white" className="svg-elem-14"></path>
                    <path d="M40.5963 24.3142H41.151V31.9403H40.5963V24.3142Z" fill="white"
                          className="svg-elem-15"></path>
                    <path
                        d="M42.8445 31.4748L47.7074 24.8292H43.003V24.3142H48.4007V24.7896L43.5378 31.4253H48.4205V31.9403H42.8544V31.4748H42.8445Z"
                        fill="white" className="svg-elem-16"></path>
                    <path d="M50.1438 24.3142H50.6984V31.9403H50.1438V24.3142Z" fill="white"
                          className="svg-elem-17"></path>
                    <path
                        d="M53.0952 24.3142H53.6399L58.3145 30.9796V24.3142H58.8692V31.9403H58.3145L53.6498 25.265V31.9403H53.0952V24.3142Z"
                        fill="white" className="svg-elem-18"></path>
                    <path
                        d="M67.3767 25.2155L67.0201 25.6017C66.6041 25.2155 65.9307 24.7104 64.663 24.7104C62.7812 24.7104 61.4343 26.2059 61.4343 28.1669C61.4343 30.1278 62.7812 31.5738 64.6531 31.5738C66.0297 31.5738 66.8121 30.9499 67.0894 30.6726V28.7611H65.2572V28.2461H67.6342V30.8409C66.8022 31.7521 65.792 32.0987 64.6531 32.0987C62.4841 32.0987 60.8499 30.4349 60.8499 28.1669C60.8499 25.8989 62.4841 24.1855 64.663 24.1855C66.0891 24.1855 66.9805 24.8391 67.3767 25.2254V25.2155Z"
                        fill="white" className="svg-elem-19"></path>
                    <path d="M74.7254 24.8292H72.5366V24.3142H77.4688V24.8292H75.28V31.9403H74.7254V24.8292Z"
                          fill="white" className="svg-elem-20"></path>
                    <path
                        d="M82.1138 24.1756C84.2728 24.1756 85.9664 25.8989 85.9664 28.1272C85.9664 30.3556 84.253 32.0789 82.094 32.0789C79.9349 32.0789 78.2512 30.4052 78.2512 28.1272C78.2512 25.8493 79.9646 24.1756 82.1039 24.1756H82.1138ZM82.1138 31.5639C83.9559 31.5639 85.392 30.1179 85.392 28.1272C85.392 26.1366 83.9559 24.6906 82.1138 24.6906C80.2716 24.6906 78.8356 26.1663 78.8356 28.1272C78.8356 30.0882 80.2617 31.5639 82.1138 31.5639Z"
                        fill="white" className="svg-elem-21"></path>
                    <path
                        d="M91.3244 24.1756C93.4835 24.1756 95.1771 25.8989 95.1771 28.1272C95.1771 30.3556 93.4637 32.0789 91.3046 32.0789C89.1456 32.0789 87.4619 30.4052 87.4619 28.1272C87.4619 25.8493 89.1753 24.1756 91.3145 24.1756H91.3244ZM91.3244 31.5639C93.1666 31.5639 94.6026 30.1179 94.6026 28.1272C94.6026 26.1366 93.1666 24.6906 91.3244 24.6906C89.4823 24.6906 88.0462 26.1663 88.0462 28.1272C88.0462 30.0882 89.4724 31.5639 91.3244 31.5639Z"
                        fill="white" className="svg-elem-22"></path>
                    <path d="M97.1381 24.3142H97.6927V31.4253H100.733V31.9403H97.1381V24.3142Z" fill="white"
                          className="svg-elem-23"></path>
                    <path
                        d="M136.268 25.2075C136.03 25.7848 135.3 27.5252 134.655 29.0788L133.466 31.9059L133.772 31.8804C134.069 31.8549 134.077 31.8464 134.493 30.8531L134.918 29.8599L136.752 29.8768L138.577 29.9023L139.001 30.8956C139.426 31.8974 139.434 31.8974 139.715 31.8974C139.91 31.8974 139.995 31.8634 139.961 31.787C139.655 30.989 136.82 24.2821 136.769 24.2312C136.735 24.1972 136.505 24.6302 136.268 25.2075ZM137.567 27.4658C137.983 28.4591 138.297 29.2911 138.271 29.308C138.246 29.3335 137.541 29.342 136.692 29.3335L135.164 29.308L135.937 27.4148C136.361 26.3791 136.735 25.5556 136.769 25.5896C136.803 25.632 137.159 26.4725 137.567 27.4658Z"
                        fill="white" className="svg-elem-24"></path>
                    <path
                        d="M147.253 24.2992C145.759 24.6812 144.63 25.9801 144.392 27.5847C144.163 29.1552 144.944 30.7853 146.303 31.5663C147.483 32.237 149.181 32.2115 150.318 31.4899C150.641 31.2861 150.683 31.2267 150.683 30.9466V30.6324L150.259 30.9211C149.614 31.3541 149.053 31.5154 148.221 31.5069C146.795 31.4984 145.666 30.7343 145.14 29.4184C144.876 28.7647 144.885 27.4658 145.156 26.7866C145.377 26.2263 146.031 25.4537 146.557 25.1481C147.635 24.5199 149.189 24.5878 150.25 25.3179L150.683 25.6151V25.3009C150.683 25.0123 150.641 24.9529 150.318 24.7491C150.114 24.6218 149.783 24.452 149.571 24.384C149.028 24.1888 147.865 24.1463 147.253 24.2992Z"
                        fill="white" className="svg-elem-25"></path>
                    <path
                        d="M119.356 28.1195V31.8974H119.611H119.866V31.371V30.8532L120.112 31.1928C120.273 31.4135 120.536 31.6257 120.859 31.7785C121.521 32.1096 122.481 32.1266 123.151 31.821C123.678 31.5918 124.297 30.955 124.561 30.3862C124.824 29.8174 124.824 28.544 124.561 27.9752C124.297 27.4064 123.678 26.7696 123.151 26.5404C122.481 26.2348 121.521 26.2518 120.859 26.5829C120.536 26.7357 120.273 26.9479 120.112 27.1687L119.866 27.5082V25.9292V24.3416H119.611H119.356V28.1195ZM122.693 26.9225C123.304 27.1432 123.669 27.4743 123.958 28.0516C124.569 29.2996 124.119 30.7768 122.939 31.3541C122.498 31.5748 121.555 31.5748 121.105 31.3541C120.137 30.8702 119.628 29.7665 119.866 28.6459C120.137 27.3045 121.479 26.4895 122.693 26.9225Z"
                        fill="white" className="svg-elem-26"></path>
                    <path d="M141.854 28.1195V31.8974H142.109H142.363V28.1195V24.3416H142.109H141.854V28.1195Z"
                          fill="white" className="svg-elem-27"></path>
                    <path
                        d="M126.488 29.1892L127.574 31.9229L127.192 32.8228C126.666 34.0623 126.674 34.0198 126.98 34.0198H127.235L128.525 30.9805C129.238 29.3165 129.968 27.6101 130.147 27.2026L130.469 26.4555L130.164 26.481L129.858 26.5065L128.873 28.8581C128.33 30.1485 127.863 31.1843 127.838 31.1503C127.804 31.1163 127.371 30.0466 126.878 28.7732L125.961 26.464H125.681H125.401L126.488 29.1892Z"
                        fill="white" className="svg-elem-28"></path>
                </svg>
            </div>
        ) : <> {children} </>
};
