import styles from "../../styles/Home.module.css";
import cn from "classnames";
import Results from "../Results/Results";
import closeIcon from "../../assets/close.svg";
import {translate} from "../../utils/translate";
import arrowDown from "../../assets/arrow_down.svg";
import React, {Dispatch, SetStateAction} from "react";
import {InputValuesType, ResultType, StoredUnitsType} from "../../utils/types";

interface ResultInterfaceProps {
    isResultViewOpen: boolean,
    isFormSubmitting: boolean,
    setIsResultViewOpen: Dispatch<SetStateAction<boolean>>,
    results: ResultType,
    inputValues: InputValuesType,
    units: StoredUnitsType
}

export const ResultInterface = ({
    isResultViewOpen, isFormSubmitting, results, inputValues, units, setIsResultViewOpen
}: ResultInterfaceProps) => {
    const { solution: { heat_exchanger_size, heat_exchanger_selection, heat_load }} = results;
    return (
        <div className={styles.home_results}>
            <div className={cn(styles.home_results_container, isResultViewOpen && styles.opened)}>
                <div
                    className={
                        isResultViewOpen
                            ? styles.home_results_visible
                            : styles.home_results_invisible
                    }
                >
                    <Results results={results} isFormSubmitting={isFormSubmitting} inputValues={inputValues} storedUnits={units}/>

                    <figure
                        onClick={() => setIsResultViewOpen(false)}
                        className={styles.home_results_closeSVG}
                    >
                        <img
                            src={closeIcon}
                            alt="close"
                            width={25}
                            height={25}
                        />
                    </figure>
                </div>

                <div
                    className={styles.home_results_nav_mobile}
                    onClick={() => setIsResultViewOpen((prevState) => !prevState)}
                >
                    {heat_exchanger_size &&
                    heat_exchanger_size !== '-' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className={styles.home_results_nav_text}>
                                {heat_exchanger_selection}
                            </p>
                            <p className={styles.home_results_nav_text}>
                                {heat_load}
                            </p>
                        </div>
                    ) : (
                        <p
                            className={styles.home_results_nav_text}
                            data-cy="solutions-button"
                        >
                            {translate('Solutions')}
                        </p>
                    )}
                    <figure>
                        <img
                            className={cn(isResultViewOpen ? styles.home_results_svg_arrow : styles.home_results_svg_arrow_rotate)}
                            src={arrowDown}
                            alt="arrow"
                            width={40}
                            height={40}
                        />
                    </figure>
                </div>
            </div>
        </div>
    );
};
