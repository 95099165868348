import { object, string, mixed } from "yup";
import {LimitationSchema} from "../types";
import validateTemperature from "../helpers/validateTemperature";
import {validateSliders} from "../helpers/validateSliders";

export const setSchema = (limits: LimitationSchema) => {
    const {cold_medium: coldLimits, hot_medium: hotLimits} = limits;
    const hotMediums = ['BIOMASS', 'FLUE_GAS', 'HOT_AIR'];
    const coldMediums = ['ETHYLENE_GLYCOL_20', 'ETHYLENE_GLYCOL_30', 'ETHYLENE_GLYCOL_40', 'ETHYLENE_GLYCOL_50', 'PROPYLENE_GLYCOL_20', 'PROPYLENE_GLYCOL_30', 'PROPYLENE_GLYCOL_40', 'PROPYLENE_GLYCOL_50', 'THERMINOL_66', 'WATER'];
    const temperatureUnits = ['K', '°C', '°F'];

    return object().shape({
        hot_medium: mixed().oneOf(hotMediums).required(),
        hot_inlet_temperature_of_medium_unit: mixed().oneOf(temperatureUnits).required(),
        hot_outlet_temperature_of_medium_unit: mixed().oneOf(temperatureUnits).required(),
        hot_inlet_temperature_of_medium: string().test({
                name: 'hot_inlet_temperature_of_medium',
                test: validateTemperature('hot_medium', 'hot_inlet_temperature_of_medium_unit', hotLimits),
            }).required('Value should be provided'),
        hot_outlet_temperature_of_medium: string().test({
                name: 'hot_outlet_temperature_of_medium',
                test: validateTemperature('hot_medium', 'hot_outlet_temperature_of_medium_unit', hotLimits),
            }).required('Value should be provided'),
        hot_lambda: string().test({
            name: 'hot_lambda',
            test: (value, { createError }) => {
                if (Number(value) < 1 ) return createError({ message: 'Should be greater than or equal to 1'});
                if (Number(value) > 2 ) return createError({ message: 'Should be less than or equal to 2'});
                return true;
            }
        }).required(),
        minimum_oversize_unit: string().required(),
        minimum_oversize: string().test({
            name: 'minimum_oversize',
            test: (value, { createError }) => {
                if (Number(value) < 0 ) return createError({ message: 'Should be greater than or equal to 0'});
                if (Number(value) > 100 ) return createError({ message: 'Should be less than or equal to 100'});
                return true;
            }
        }).required(),
        hot_fouling_unit: string().required(),
        hot_fouling: string().required(),
        hot_boiler_gas_input_unit: string().required(),
        hot_allowable_pressure_drop_of_medium: string().required(),
        hot_allowable_pressure_drop_of_medium_unit: string().required(),
        hot_mass_flow_of_medium_unit: string().required(),
        hot_medium_humidity_level: string().when('hot_medium', {
            is: 'BIOMASS',
            then: schema => schema.required('Value should be provided'),
            otherwise: schema => schema.optional()
        }),
        cold_medium: mixed().oneOf(coldMediums).required(),
        cold_inlet_temperature_of_medium_unit: mixed().oneOf(temperatureUnits).required(),
        cold_outlet_temperature_of_medium_unit: mixed().oneOf(temperatureUnits).required(),
        cold_inlet_temperature_of_medium: string().test({
                name: 'cold_inlet_temperature_of_medium',
                test: validateTemperature('cold_medium', 'cold_inlet_temperature_of_medium_unit', coldLimits),
            }).required('Value should be provided'),
        cold_outlet_temperature_of_medium: string().test({
                name: 'cold_outlet_temperature_of_medium',
                test: validateTemperature('cold_medium', 'cold_outlet_temperature_of_medium_unit', coldLimits),
            }).required('Value should be provided'),
        cold_fouling: string().required(),
        cold_fouling_unit: string().required(),
        cold_mass_flow_of_medium_unit: string().required(),
        cold_allowable_pressure_drop_of_medium_unit: string().required(),
        cold_allowable_pressure_drop_of_medium: string().required(),
        hot_mass_flow_of_medium: string().test({ name: 'hot_mass_flow_of_medium', test: (value, { parent, createError }) => {
                const { hot_medium, hot_boiler_gas_input, cold_mass_flow_of_medium, hot_mass_flow_of_medium } = parent;
                if (hot_medium === 'HOT_AIR' && !value) return createError({ message: 'Value should be provided for HOT AIR' });
                else {
                    if (hot_mass_flow_of_medium || hot_boiler_gas_input || cold_mass_flow_of_medium) {
                        return (
                            (hot_mass_flow_of_medium && !hot_boiler_gas_input && !cold_mass_flow_of_medium) ||
                            (!hot_mass_flow_of_medium && hot_boiler_gas_input && !cold_mass_flow_of_medium) ||
                            (!hot_mass_flow_of_medium && !hot_boiler_gas_input && cold_mass_flow_of_medium)
                        );
                    }
                    return true;
                }
            } }),
        hot_boiler_gas_input: string().test({ name: 'hot_boiler_gas_input', test: validateSliders }),
        cold_mass_flow_of_medium: string().test({ name: 'cold_mass_flow_of_medium', test: validateSliders }),
    }).required();
}
