import React from 'react';
import "../../styles/common.css"
import {translate} from '../../utils/translate';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';

interface IForm {
  children: React.ReactNode;
  setInit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  changeTab: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hot: boolean;
}

const Form = ({ children, setInit, title, changeTab, hot }: IForm) => {
  return (
    <>
      <p className="py-2 pl-4 text-xl font-bold underline decoration-1 underline-offset-8">
        {title}
      </p>
      {children}
      <div className="mt-auto pt-6 flex items-center justify-between gap-4">
        <div className="w-1/2 xl:w-1/3">
            <button onClick={setInit} className="est_button mt-0" data-testid='clear-form'>
              {translate('CLEAR FORM')}
            </button>
        </div>
          <div className="color w-full flex justify-end text-white/80 underline decoration-white/80 underline-offset-4 hover:text-white hover:decoration-white">
              <button
                  data-testid='form-button'
                  className="color text-white/80 underline decoration-white/80 underline-offset-4 hover:text-white hover:decoration-white flex items-center justify-center"
                  onClick={changeTab}
              >
            {hot ? (
                <>
                    <span className="text-base sm:text-xl">{translate('Fill the cold side and see the results')}</span>
                    <MdOutlineKeyboardArrowLeft className="h-12 w-12 rotate-180"/>
                </>

            ) : (
                <>
                    <MdOutlineKeyboardArrowLeft className="h-12 w-12" />
                    <span className="text-base sm:text-xl">{translate('Back to hot side')}</span>
                </>
            )}
              </button>
          </div>
      </div>
    </>
  );
};

export default Form;
