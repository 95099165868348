import React, {FC, ReactNode} from 'react';
import cn from "classnames";
import styles from "./modal.module.css";


type ModalProps = {
    children: ReactNode,
    isClosing: boolean,
    isOpen: boolean,
}

const ModalWrapper: FC<ModalProps> = ({children, isClosing, isOpen}) => {
    if (!isOpen) return null;
    return (
        <div className={cn(styles.modal, isClosing && styles.closing)}>

            {children}

        </div>
    );
};

export default ModalWrapper;
