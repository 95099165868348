import React, {useState} from 'react';
import styles from './Results.module.css';
//DATA FOR CONFIGURATIONS
import {tabs} from "../../utils/dataConfigurations";
import Specification from "../Tabs/Specification";
import Properties from "../Tabs/Properties";
import Configuration from "../Tabs/Configuration";
import {InputValuesType, ResultType, StoredUnitsType} from "../../utils/types";
import {Spinner} from "../Spinner/Spinner";


type ResultsType = {
    results: ResultType,
    isFormSubmitting: boolean,
    inputValues: InputValuesType,
    storedUnits: StoredUnitsType
}

const Results: React.FC<ResultsType> = ({results, isFormSubmitting, inputValues, storedUnits}): JSX.Element => {

    const [chosenTab, setChosenTab] = useState<string>(Object.keys(tabs)[0]);

    const renderTabContent = (tab: string) => {
        switch (tab) {
            case 'tab1':
                return <Specification results={results} inputValues={inputValues} setChosenTab={setChosenTab}/>;
            case 'tab2':
                return <Properties results={results}/>;
            case 'tab3':
                return <Configuration results={results} inputValues={inputValues} storedUnits={storedUnits}/>;
            default:
                return <></>;
        }
    }

    return (
        <div className={styles.results} data-cy="results-window">
            {/* NAV  */}
            <div className={styles.results_section_nav}>
                {Object.keys(tabs).map(tabname => {
                    return (
                        <div data-testid={tabname} key={tabname} onClick={() => setChosenTab(tabname)}
                             className={styles.results_section_nav_item}>
                            {/* @ts-ignore */}
                            <p className={`${styles.results_section_nav_text} ${chosenTab === tabname && styles.results_section_nav_text_active}`}>{tabs[tabname].title}</p>
                            <p className={`${chosenTab === tabname && styles.results_section_nav_line_active}`}></p>
                        </div>
                    )
                })}
            </div>

            {isFormSubmitting? <Spinner/> : renderTabContent(chosenTab)}

        </div>
    )
}

export default Results;
