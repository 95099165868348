import {useState, FC, RefObject, ChangeEvent} from 'react';
import styles from "./modal.module.css";
import {translate} from "../../utils/translate";
import {useForm} from "react-hook-form";
import {validEmailRegex} from "../../utils/constants";
import {useAppContext} from "../../context/AppContext";
import Loader from "./Loader";
import api from "../../api";
import cn from "classnames";

type LoginModalProps = {
    closeModal: () => void;
    reference: RefObject<HTMLDivElement>;
    isSendMailMode?: boolean;
}

type LoginData = {
    email: string
}

const RequireLoginModal: FC<LoginModalProps> = ({
      closeModal,
      reference,
      isSendMailMode
}) => {

    const [enteredCode, setEnteredCode] = useState('');
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [wrongEmailMessage, setWrongEmailMessage] = useState('');
    const [wrongCodeMessage, setWrongCodeMessage] = useState('');


    const { userInfo } = useAppContext();

    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors}
    } = useForm<LoginData>({
        defaultValues: {
            email: ''
        }
    });

    const handleLogin = (data: LoginData) => {
        setIsModalLoading(true)
        api.registerEmail(data.email)
            .then(res => {
                setIsModalLoading(false)
                userInfo?.rememberEmail(data.email)
                userInfo?.toggleWaitingForCode(true)
            })
            .catch(err => {
                console.log(err)
                setIsModalLoading(false)
                if(err.response) {
                    setWrongEmailMessage(err.response.data.localized_message.description)
                }else setWrongEmailMessage(err.message)
            })
    }


    const confirmCode = () => {
        setIsModalLoading(true)
        api.checkCodeFromMail(userInfo?.userEmail!, enteredCode)
            .then(res => {
                const timeoutSuccess = setTimeout(() => {
                    userInfo?.logUserIn(res.data.csrf)
                    userInfo?.toggleWaitingForCode(false)
                    !isSendMailMode && closeModal()  // prevent closing modal while sending mail
                }, 500)
                return () => clearTimeout(timeoutSuccess)
            }).catch(err => {
                console.log(err)
                setIsModalLoading(false)
                if(err.response) setWrongCodeMessage(err.response.data.message)
                else setWrongCodeMessage(err.message)
            })
    }

    const onCodeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEnteredCode(e.target.value)
        setWrongCodeMessage('')
    }

    const backToRegisterEmail = () => {
        setValue('email',  userInfo?.userEmail ||'');
        userInfo?.toggleWaitingForCode(false);
        setEnteredCode('');
        setWrongCodeMessage('');
    }

    const enterEmail = (
        <>
            <h5 data-testid="enter-email-title" className={styles.modal_header}>Enter your email to sign in</h5>
            <input type="email"
                   className={styles.modal_input}
                   {...register('email', {
                       required: true,
                       pattern: validEmailRegex,
                   })}
            />
            {errors.email && <span className={styles.modal_error}>* {translate('Enter correct email')}</span>}
            {wrongEmailMessage && <span className={styles.modal_error}>* {translate(wrongEmailMessage)}</span>}

            <div className={styles.modal_btn_container}>
                <button className={styles.modal_btn} type="button" onClick={closeModal}>
                    {translate('CLOSE')}
                </button>
                <button className={styles.modal_btn} type="submit" onClick={handleSubmit(handleLogin)}>
                    {translate('SUBMIT')}
                </button>
            </div>
        </>
    )

    const confirmEmail = (
            <>
                <h5 data-testid="confirm-email-title" className={styles.modal_header}>Input the code received in your email</h5>
                <input type="text"
                       data-testid="confirm-code"
                       className={styles.modal_input}
                       value={enteredCode}
                       onChange={onCodeInputChange}
                />
                {wrongCodeMessage && <span className={styles.modal_error}>* {translate(wrongCodeMessage)}</span>}
            <div className={styles.modal_btn_container}>
                <button className={styles.modal_btn} type="button" onClick={closeModal}>
                    {translate('CLOSE')}
                </button>
                <button className={styles.modal_btn} type="submit" onClick={confirmCode}>
                    {translate('CONFIRM')}
                </button>
            </div>
            <div className={styles.login_back_link} onClick={backToRegisterEmail}>
                <p>* Check email and send another code</p>
            </div>
        </>
    )

    return (
            <div ref={reference} className={cn(styles.modal_body, styles.modal_login_body)}>
                <div>
                    <form className={styles.modal_login_container} onSubmit={handleSubmit(handleLogin)}>
                        {isModalLoading
                            ? <Loader/>
                            : userInfo?.waitingForCode
                                ? confirmEmail
                                : enterEmail
                        }
                    </form>
                </div>
            </div>
    );
};

export default RequireLoginModal;
