import {CalculatedInputType, ColdMediums, HotMediums, InputValuesType, SolutionType} from "../types";
import {mediumsLabels} from "../constants";

interface UseProvidedDataParams {
    result: {
        solution: SolutionType,
        form: CalculatedInputType,
    },
    userData: InputValuesType
}

export const UseProvidedData = ( params: UseProvidedDataParams )  => {
    const { result: { solution, form }, userData} = params;

    const projectData = [
        { title: 'Boiler Capacity', value: solution.boiler_capacity},
        { title: 'Sensible Heat', value: solution.sensible_heat},
        { title: 'Latent Heat', value: solution.latent_heat},
        { title: 'Heat Load', value: solution.heat_load},
        { title: 'LMTD', value: solution.LMTD},
        { title: 'Min. Oversizing', value: solution.min_oversizing},
    ];

    const projectValues = [
        {
            title: 'Fluid',
            hotValue: mediumsLabels.hot_medium[userData.hot_medium as HotMediums],
            hotUnit: '',
            coldValue: mediumsLabels.cold_medium[userData.cold_medium as ColdMediums],
            coldUnit: ''
        },
        null,

        {
            title: 'Inlet Temperature',
            hotValue: userData.hot_inlet_temperature_of_medium,
            hotUnit: userData.hot_inlet_temperature_of_medium_unit,
            coldValue: userData.cold_inlet_temperature_of_medium,
            coldUnit: userData.cold_inlet_temperature_of_medium_unit
        },
        {
            title: 'Outlet Temperature',
            hotValue: userData.hot_outlet_temperature_of_medium,
            hotUnit: userData.hot_outlet_temperature_of_medium_unit,
            coldValue: userData.cold_outlet_temperature_of_medium,
            coldUnit: userData.cold_outlet_temperature_of_medium_unit
        },
        {
            title: 'Mass Flow',
            hotValue: form.hot_mass_flow_of_medium.value,
            hotUnit: userData.hot_mass_flow_of_medium_unit,
            coldValue: form.cold_mass_flow_of_medium.value,
            coldUnit: userData.cold_mass_flow_of_medium_unit
        },
        {
            title: 'Max. Pressure Drop',
            hotValue: userData.hot_allowable_pressure_drop_of_medium,
            hotUnit: userData.hot_allowable_pressure_drop_of_medium_unit,
            coldValue: userData.cold_allowable_pressure_drop_of_medium,
            coldUnit: userData.cold_allowable_pressure_drop_of_medium_unit
        },
    ];

    const exchangerSelectionTable = [
        {
            exNumber: 'Heat exchanger',
            oversize: 'Oversize[%]',
            dpHotSide: 'dP hot side [Pa]',
            dpColdSide: 'dP cold side [kPa]',
            HTA: 'HTA [m2]'
        },
        {
            exNumber: solution.heat_exchanger_selection,
            oversize: solution.oversize,
            dpHotSide: solution.dp_fluegas_side_pa,
            dpColdSide: solution.dp_water_side_kpa,
            HTA: solution.heat_transfer_area
        }
    ];

    const physicalProps = [
        {
            title: 'Fluid',
            hotValue: mediumsLabels.hot_medium[solution.hot_side.medium as HotMediums],
            coldValue: mediumsLabels.cold_medium[solution.cold_side.medium as ColdMediums],
        },
        null,
        {
            title: 'Reference Temperature',
            hotValue: solution.hot_side.reference_temperature,
            coldValue: solution.cold_side.reference_temperature,
        },
        {
            title: 'Specific Heat',
            hotValue: solution.hot_side.specific_heat,
            coldValue: solution.cold_side.specific_heat,
        },
        {
            title: 'Density',
            hotValue: solution.hot_side.density,
            coldValue: solution.cold_side.density,
        },
        {
            title: 'Thermal Conductivity',
            hotValue: solution.hot_side.thermal_conductivity,
            coldValue: solution.cold_side.thermal_conductivity,
        },
        {
            title: 'Dynamic Viscosity',
            hotValue: solution.hot_side.dynamic_viscosity,
            coldValue: solution.cold_side.dynamic_viscosity,
        },
        {
            title: 'Kinematic Viscosity',
            hotValue: solution.hot_side.kinematic_viscosity,
            coldValue: solution.cold_side.kinematic_viscosity,
        },
        {
            title: 'Prandtl Number',
            hotValue: solution.hot_side.prandtl_number,
            coldValue: solution.cold_side.prandtl_number,
        },
    ];

        return {
            projectData,
            projectValues,
            exchangerSelectionTable,
            physicalProps
    }
}
