import {LinkForFile} from "./LinkForFile";
import React from "react";
import {InputValuesType, ResultType} from "../../utils/types";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {PDFDocument} from "../PDFDocument/PDFDocument";
import styles from "../Results/Results.module.css";
import { FiDownload } from "react-icons/fi";
import {translate} from "../../utils/translate";

interface RenderFilesProps {
    solution: ResultType,
    providedValues: InputValuesType,
    customer: string,
}

export const Files = ({ solution, providedValues, customer }: RenderFilesProps) => {

    return (
            <div className='flex flex-col items-start'>
                {solution.solution.files?.dwg?.map(dwgStr =>
                    <LinkForFile key={dwgStr} url={dwgStr} title='GET EXCHANGER DRAWING' />
                )}
                {solution.solution.files?.pdf?.map(pdfStr =>
                    <LinkForFile key={pdfStr} url={pdfStr} title='GET PROMOTIONAL MATERIALS' />
                )}

                <PDFDownloadLink
                    document={<PDFDocument
                        result={solution}
                        userData={providedValues}
                        customer={customer}
                    />}
                    fileName='EST_configuration.pdf'
                    className={styles.results_tab_specification_download}
                >
                    <FiDownload/>
                    {translate('GET CONFIGURATION PDF')}
                </PDFDownloadLink>
            </div>
    )
}
