import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
import cn from "classnames";
import 'react-toastify/dist/ReactToastify.css';

import "../../styles/common.css";
import styles from "./modal.module.css";

import {translate} from "../../utils/translate";
import Loader from "./Loader";
import {useAppContext} from "../../context/AppContext";
import RequireLoginModal from "./RequireLoginModal";
import api from "../../api";
import {createPortal} from "react-dom";


type ModalProps = {
    mailContent: {
        subject: string,
        bodyToSend: string
    },
    reference: React.RefObject<HTMLDivElement>,
    closeModal: () => void,
    isClosing: boolean
}

type FormData = {
    name: string,
    email: string,
    company: string,
    phoneNumber: string,
    comment: string
}

const SendMailModal: React.FC<ModalProps> = ({
     mailContent: {subject, bodyToSend},
     reference,
     closeModal,
     isClosing
}) => {

    const [loading, setLoading] = useState(false);
    const [mailStatus, setMailStatus] = useState('');

    const { userInfo } = useAppContext();

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<FormData>({
        defaultValues: {
            name: '',
            email: '',
            company: '',
            phoneNumber: '',
            comment: ''
        }
    });


    const handleSendEmail = (data: FormData) => {

        const { name, company, comment, phoneNumber } = data;
        setLoading(true)

        const content = `
        ${bodyToSend}
        ${comment && `
Additional comment:
 
    ${comment}`}
        
        ${phoneNumber && `
Contact me: ${phoneNumber}`}
        
Kind regards
${name} 
${company && `${company}`}
        `

        api.sendMail(subject, content, userInfo?.userToken!)
            .then(res => {
                setLoading(false)
                setMailStatus(res.data.status)
                const timeoutSuccess = setTimeout(() => {
                    closeModal()
                }, 1000)
                return () => clearTimeout(timeoutSuccess)
            })
            .catch(error => {
                console.log(error)
                setMailStatus('')
                setLoading(false)
                if(error.response) toast.error(error.response.data.message)
                else toast.error(error.message);
                userInfo?.logUserOut();
            })
    }


    const modalForm = (
        <div ref={reference} className={styles.modal_body + ' bg-left'}>
            <h3 className={styles.modal_header}>Check the information</h3>

            <form className={styles.modal_content}>
                <div className={styles.modal_content_info}>
                    <div className={styles.content_info__item}>
                        <p>{translate('Enter your name*')}: </p>
                        <input
                            className={cn(styles.modal_input, errors.name && styles.input_invalid)}
                            type="text"
                            {...register('name', {required: true})}
                        />
                        {errors.name && <span className={styles.modal_error}>* {translate('Enter your name')}</span>}
                    </div>
                    <div className={styles.content_info__item}>
                        <p>{translate('Enter your email*')}: </p>
                        <input
                            className={cn(styles.modal_input, errors.email && styles.input_invalid)}
                            type="email"
                            value={userInfo?.userEmail}
                            readOnly
                        />
                    </div>
                    <div className={styles.content_info__item}>
                        <p>{translate('Company name')}: </p>
                        <input
                            className={styles.modal_input}
                            type="text"
                            {...register('company')}
                        />
                    </div>
                    <div className={styles.content_info__item}>
                        <p>{translate('Phone number')}: </p>
                        <input
                            className={styles.modal_input}
                            type="number"
                            {...register('phoneNumber')}
                        />
                    </div>
                </div>

                <div className={styles.modal_content_info}>
                    <p>{translate('Mail subject')}: </p>
                    <input
                        readOnly
                        className={cn(styles.modal_input, styles.readonly)}
                        type="text"
                        value={subject}
                    />
                </div>

                <div className={cn(styles.modal_content_info, styles.grown_item)}>
                    <p>{translate('Mail content')}: </p>
                    <textarea
                        readOnly
                        value={bodyToSend}
                        className={cn(styles.modal_textarea, styles.readonly, styles.modal_textarea__readonly)}
                    />
                </div>
                <div className={styles.modal_content_info}>
                    <p>{translate('Optional comment')}: </p>
                    <textarea
                        {...register('comment')}
                        className={cn(styles.modal_textarea, styles.modal_textarea__editable, styles.modal_input)}
                    />
                </div>
            </form>

            <div className={styles.modal_btn_container}>
                <button className={styles.modal_btn + " est_button"} onClick={closeModal}>
                    {translate('GO BACK')}
                </button>
                <button className={styles.modal_btn + " est_button"} onClick={handleSubmit(handleSendEmail)}>
                    {translate('SEND MAIL')}
                </button>
            </div>
        </div>)


    const sentNotification = (
        <div className={styles.modal_sent_success}>
            <span>{translate(mailStatus)}</span>
        </div>)



    return createPortal(
        <div data-testid='modal' className={cn(styles.modal, isClosing && styles.closing)}>
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar
            />
            {loading
                ? <Loader/>
                : mailStatus
                    ? sentNotification
                    : userInfo?.userToken
                        ? modalForm
                        : <RequireLoginModal
                            reference={reference}
                            closeModal={closeModal}
                            isSendMailMode={true}
                        />
            }
        </div>,
        document.body
    )
}

export default SendMailModal;
