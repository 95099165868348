import styles from "../Results/Results.module.css";
import {translate} from "../../utils/translate";
import React, {Dispatch, SetStateAction} from "react";
import {heaterConfigType} from "../../utils/types";

interface SectionRow {
    id: string,
    name: string,
    img?: string;
}
interface ConfigRowProps {
    section: {
        id: string,
        name: string,
        rows: SectionRow[]
    },
    config: heaterConfigType,
    changeConfig: Dispatch<SetStateAction<heaterConfigType>>,
}

const ConfigRow = ({ section, config, changeConfig }: ConfigRowProps) => {
  return (
      <div key={section.id} className={styles.results_tab3_inlet_setings}>
          <p className={styles.results_tab3_inlet_setings_title}>{translate(section.name)}</p>
          {section.rows.map(row => {
              return (
                  <div className={styles.results_tab3_inlet_setings_row}
                       key={row.id}>
                      <input
                          checked={config[section.id].name === row.name}
                          onChange={() => changeConfig(prevState => ({
                              ...prevState,
                              [section.id]: row
                          }))}
                          className={styles.results_tab3_inlet_setings_row_input}
                          type="radio"
                          id={row.id}
                          name={section.id}
                          value={row.id}/>
                      <label
                          className={styles.results_tab3_inlet_setings_row_label}
                          htmlFor={row.id}>{translate(row.name)}</label>
                  </div>
              )
          })}
      </div>
  )
}

export default ConfigRow;
