import React from 'react';
import {validateNumber} from "../../../utils/validateNumber";
import styles from "../../../styles/Home.module.css";
import {InputValuesType, itemType} from "../../../utils/types";
import {UseFormRegister, UseFormTrigger} from "react-hook-form";

type InputItemProps = {
    item: itemType,
    register: UseFormRegister<InputValuesType>,
    trigger: UseFormTrigger<InputValuesType>
}


const InputItem = ({item, register, trigger}: InputItemProps) => {
    const isFloat = item.id === 'hot_fouling' || item.id === 'cold_fouling' || item.id === 'hot_lambda';
    return (
        <input
            {...register(item.id)}
            onInput={() => trigger([
                'cold_inlet_temperature_of_medium',
                'cold_outlet_temperature_of_medium',
                'hot_outlet_temperature_of_medium',
                'hot_inlet_temperature_of_medium'
            ])}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => validateNumber(e, isFloat)}
            onWheel={() => (document.activeElement as HTMLInputElement).blur()}
            type='text'
            autoComplete='off'
            className={styles.home_input}/>
    )
}

export default InputItem;
