import axios from "axios";
import {InputValuesType} from "../utils/types";


const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

const authConfig = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

const withTokenConfig = (token: string) => ({
    withCredentials: true,
        headers: {
        'Xsrf-Token': token
    }
})


const api = {

    /*  ======= init data getters =========  */

    getLimitations() {
        return instance.get('/mediums/');
    },

    getUnits() {
        return instance.get('/units/')
    },


    /* ========== Solution searching ============= */

    findSolution(inputValues: InputValuesType) {
        return instance.post(
            '/solution/find',
            inputValues
        )
    },

    getProductIndex(data: object) {
        return instance.post('/solution/index', data)
    },

    /* ==========  Auth manager  ========= */

    registerEmail(email: string) {
        return instance.post(
            '/auth/login',
            {
                Email: btoa(email)
            },
            {...authConfig}
        )
    },

    checkCodeFromMail(email: string, code: string) {
        return instance.post(
            '/auth/authenticate',
            {
                Email: btoa(email),
                Code: btoa(code)
            },
            {...authConfig, withCredentials: true}
        )
    },

    sendMail(title: string, body: string, token: string) {
        return instance.post(
            '/send_mail/',
            {
                title: title,
                body: body
            },
            withTokenConfig(token)
        )
    },

    isUserLoggedIn(token: string) {
        return instance.get(
            '/auth/login',
            withTokenConfig(token)
        )
    },

    getUserInfo(token: string) {
        return instance.get(
            '/user',
            withTokenConfig(token)
        )
    },

    logOut() {
        return instance.delete('/auth/logout')
    },



    /* ========== Download file ============= */

    downloadFile(uri: string, token: string) {
        return instance.get(
            uri,
            {
                responseType: "blob",
                ...withTokenConfig(token)
            }
        )
            .then(res => {
                const filename = res.request.getResponseHeader('Content-Disposition').split('filename=')[1];
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                link.parentNode!.removeChild(link);
            })
    },
}

export default api;
