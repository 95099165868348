import {sliderLabels} from "../constants";

export const clearAnotherSliders = (
    id: typeof sliderLabels[number],
    setFn: (k: typeof sliderLabels[number], v: string) => void,
) => {
    if (id === 'hot_boiler_gas_input') {
        setFn('hot_mass_flow_of_medium', '');
        setFn('cold_mass_flow_of_medium', '');
    }
    if (id === 'hot_mass_flow_of_medium') {
        setFn('hot_boiler_gas_input', '');
        setFn('cold_mass_flow_of_medium', '');
    }
    if (id === 'cold_mass_flow_of_medium') {
        setFn('hot_boiler_gas_input', '');
        setFn('hot_mass_flow_of_medium', '');
    }
}
