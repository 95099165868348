import React from 'react';
import styles from "../Results/Results.module.css";
import {translate} from "../../utils/translate";
import {tabs} from "../../utils/dataConfigurations";
import {ResultType} from "../../utils/types";
import cn from "classnames";

interface PropertiesProps {
    results: ResultType
}

const Properties = ({ results }: PropertiesProps) => {
    const { solution: { hot_side, cold_side } } = results;
    return (
        <div className={styles.results_tab_container}>
            <div className={styles.results_tab_row}>
                <p className={styles.results_tab2_desc}></p>
                <p className={styles.results_tab2_value}>{translate('Hot side (shell)')}</p>
                <p className={styles.results_tab2_value}>{translate('Cold side (tubes)')}</p>
            </div>
            {tabs.tab2.rows.map(row => {
                return (
                    <div key={row.key} className={styles.results_tab_row}>
                        <p className={styles.results_tab2_desc}>{translate(row.name)}</p>
                        {/* @ts-ignore */}
                        <p className={cn(styles.results_tab2_value, styles.results_tab2_value_hot)}>{hot_side[row.key] ? hot_side[row.key] : row.value}</p>
                        {/* @ts-ignore */}
                        <p className={cn(styles.results_tab2_value, styles.results_tab2_value_cold)}>{cold_side[row.key] ? cold_side[row.key] : row.value}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default Properties;
