import {Dispatch, SetStateAction, useState} from "react";
import {defaultConfigurationElineBig, defaultConfigurationElineSmall} from "../dataConfigurations";
import {SIZES} from "../constants";
import {heaterConfigType} from "../types";

export type SizeType = 'ELINE-SMALL' | 'ELINE-BIG';

export interface UseConfigurationResult {
    config: heaterConfigType,
    changeConfig: Dispatch<SetStateAction<heaterConfigType>>,
}

export const UseConfiguration = (size: SizeType): UseConfigurationResult => {
    const [configurationElineSmall, setConfigurationElineSmall] = useState<heaterConfigType>(defaultConfigurationElineSmall)
    const [configurationElineBig, setConfigurationElineBig] = useState<heaterConfigType>(defaultConfigurationElineBig)

    const config = size === SIZES.small ? configurationElineSmall : configurationElineBig;
    const changeConfig = size === SIZES.small ? setConfigurationElineSmall : setConfigurationElineBig;

    return { config, changeConfig }
}
