import React, {memo} from "react";

type imgProp = {
    img: string
}
const AccessoriesImage:React.FC<imgProp> = ({img}) :JSX.Element => {

    return (
        <div>
            <img src={img} alt='accessory' width={128} height={128}/>
        </div>
    );
};

export default memo(AccessoriesImage);
