import React, {useEffect, useState, memo} from 'react';

import leftWithout_LeftWithout from '../../assets/images/elineSmall/E-line_v07.png';
import leftWithout_LeftWith from '../../assets/images/elineSmall/E-line_v06.png';
import leftWith_LeftWithout from '../../assets/images/elineSmall/E-line_v05.png';
import leftWith_LeftWith from '../../assets/images/elineSmall/E-line_v04.png';
import leftWithout_RightWithout from '../../assets/images/elineSmall/E-line_v08.png';
import leftWithout_RightWith from '../../assets/images/elineSmall/E-line_v02.png';
import leftWith_RightWithout from '../../assets/images/elineSmall/E-line_v03.png';
import leftWith_RightWith from '../../assets/images/elineSmall/E-line_v01.png';

import rightWithout_LeftWithout from '../../assets/images/elineSmall/E-line_v010.png';
import rightWithout_LeftWith from '../../assets/images/elineSmall/E-line_v012.png';
import rightWith_LeftWithout from '../../assets/images/elineSmall/E-line_v011.png';
import rightWith_LeftWith from '../../assets/images/elineSmall/E-line_v09.png';

import rightWith_RightWithout from '../../assets/images/elineSmall/E-line_v013.png';
import rightWithout_RightWithout from '../../assets/images/elineSmall/E-line_v014.png';
import rightWithout_RightWith from '../../assets/images/elineSmall/E-line_v015.png';
import rightWith_RightWith from '../../assets/images/elineSmall/E-line_v016.png';

import noImage from '../../assets/images/elineSmall/no-image.jpeg';


type configProps = {
    eline_small_inlet: {
        id: string,
        name: string
    },
    eline_small_outlet: {
        id: string,
        name: string
    }
}

interface HeaterConfigImages {
    [inlet: string] : string
}

const ElineSmallImage:React.FC<configProps> = ({
           eline_small_inlet,
           eline_small_outlet
    }) :JSX.Element => {

    const [img, setImg] = useState(leftWithout_LeftWithout);

    const confMap : {[outlet: string] : HeaterConfigImages} = {

            'inlet_left_without_flange': {
                    'outlet_left_without_flange': leftWithout_LeftWithout,
                    'outlet_left_with_flange': leftWithout_LeftWith,
                    'outlet_right_without_flange': leftWithout_RightWithout,
                    'outlet_right_with_flange': leftWithout_RightWith
            },
            'inlet_left_with_flange' : {
                    'outlet_left_without_flange': leftWith_LeftWithout,
                    'outlet_left_with_flange': leftWith_LeftWith,
                    'outlet_right_without_flange': leftWith_RightWithout,
                    'outlet_right_with_flange': leftWith_RightWith
            },
            'inlet_right_without_flange': {
                    'outlet_left_without_flange': rightWithout_LeftWithout,
                    'outlet_left_with_flange': rightWithout_LeftWith,
                    'outlet_right_without_flange': rightWithout_RightWithout,
                    'outlet_right_with_flange': rightWithout_RightWith
           },
            'inlet_right_with_flange': {
                    'outlet_left_without_flange': rightWith_LeftWithout,
                    'outlet_left_with_flange': rightWith_LeftWith,
                    'outlet_right_without_flange': rightWith_RightWithout,
                    'outlet_right_with_flange': rightWith_RightWith
            }
    }


    useEffect(() => {
        const imageFile = confMap[eline_small_inlet.id][eline_small_outlet.id] ?? noImage
        setImg(imageFile)

    }, [eline_small_inlet, eline_small_outlet])

    return (
        <div>
            <img src={img} alt='heat exchanger' />
        </div>
    );
};

export default memo(ElineSmallImage);
