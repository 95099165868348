
import {coldSideData, hotSideData, hotSideDataBIOMASS} from "./dataForms";
import {tabs} from './dataConfigurations'
import dateFormat from 'dateformat';
import {heaterConfigType, InputValuesType, itemType, MeasureType, SolutionType, StoredUnitsType} from "./types";


export const getQuotationHandler = (
    results : SolutionType,
    inputValues : InputValuesType,
    hexConfiguration: heaterConfigType,
    units: StoredUnitsType,
    productWGIndex: string
) : { bodyToSend: string, subject: string } => {

    const hotData = inputValues.hot_medium === "BIOMASS" ? hotSideDataBIOMASS : hotSideData

    const inputValuesForSideToString = (input:InputValuesType, side:itemType[]) => {
        let resString = '';

        for (const [key, value] of Object.entries(input)) {
            const param = side.find(item=>item.id===key);

            if(!param || !value || param.id === 'hot_medium' || param.id === 'cold_medium' ) {
                continue;
            }

            const fieldTitle = param.title.split(' *').join('')
            resString += `    ${fieldTitle}: ${value} ${units[param.measure as MeasureType] || ''} ${lineBreak}`
        }
        return resString
    }

    const propertiesValuesForSideToString = (side: 'hot_side' | 'cold_side') => {
        let resString = '';
        for (const [key, value] of Object.entries(results[side])) {
            const title = tabs.tab2.rows.find(elem => elem.key === key)!.name;
            resString += `    ${title} ${value} ${lineBreak}`
        }
        return resString
    }

    const now = new Date();
    const dateStr = dateFormat(now, "yyyymmmdd_HHMMss");
    const medium = results.hot_side.medium;
    const lineBreak = '\n'

    const subject = results.heat_exchanger_selection !== '-'
        ? `Inquiry ${results.heat_exchanger_selection} (${medium}) No. ESTAIC/${dateStr}`
        : 'FIND HEAT EXCHANGER'

    const configurationStr = (hexConf: heaterConfigType) => {
        let obj;
        if (results.heat_exchanger_size === 'ELINE-SMALL') {
            obj = tabs.tab3.eline_small
        } else { // (results.heat_exchanger_size === 'ELINE-BIG')
            obj = tabs.tab3.eline_big
        }
        let confStr = ''
        for (const [key, value] of Object.entries(hexConf)) {
            obj.forEach(item => {
                if(item.id === key) confStr += `    ${item.name}: ${value.name}${lineBreak}`
            })
        }
        return confStr
    }

    const bodyToSend = `
Dear AIC team,

${results.heat_exchanger_selection !== '-' 
        ? `We are interested in the Economizer *${results.heat_exchanger_selection} ${medium}* 
Product WG index: ${productWGIndex}        `
        : 'Please help to choose a heat economizer according to the following parameters:'}

Hot side (${results.hot_side.medium.split('_').join(' ')}):
${inputValuesForSideToString(inputValues, hotData)}

Cold side (${results.cold_side.medium.split('_').join(' ')}):
${inputValuesForSideToString(inputValues, coldSideData)}

Hot side properties:
${propertiesValuesForSideToString('hot_side')}

Cold side properties:
${propertiesValuesForSideToString('cold_side')}

${results.heat_exchanger_selection !== '-' 
    ? `Configuration:
${configurationStr(hexConfiguration)}` : ''}`

    return {bodyToSend, subject};
}
