import React from 'react';
import styles from './Footer.module.css';
import {translate} from "../../utils/translate";

const Footer = () => (
    <footer className="pb-36 pt-2 text-[.8rem] text-white/80 lg:pb-10 mt-auto">
      <p className={styles.footer_middle}></p>

      <div className="mx-auto -mt-2 flex w-full max-w-[1550px] flex-col justify-between gap-4 md:flex-row">
        <div className={styles.footer_bottom_left}>
          <a className={styles.footer_bottom_left_a} rel="noopener noreferrer" target="_blank" href="/legal/EST_Privacy_Policy.pdf">
              {translate('Privacy policy')}
          </a>
          <a className={styles.footer_bottom_left_a} rel="noopener noreferrer" target="_blank" href="/legal/EST_Terms_and_Conditions.pdf">
              {translate('Terms and conditions')}
          </a>
        </div>
        <div>
          <p>AIC S.A.</p>
          <p>ul. Rdestowa 41</p>
          <p>PL 81-577 Gdynia</p>
        </div>
        <div>
          <p>{translate('Copyright © 2022 AIC S.A. All Rights Reserved')}</p>
          <a rel="noopener noreferrer" className={styles.footer_bottom_left_a} href="https://www.myaic.com">
            www.myaic.com
          </a>
        </div>
      </div>
    </footer>
  );

export default React.memo(Footer);
