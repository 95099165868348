import {tabs} from "../../utils/dataConfigurations";
import styles from "../Results/Results.module.css";
import React, {Dispatch, SetStateAction} from "react";
import {translate} from "../../utils/translate";
import {ElineConfigSize, heaterConfigType} from "../../utils/types";
import {exchangersWithoutBSPP, SIZES} from "../../utils/constants";
import ElineSmallImage from "../ElineSmallImage/ElineSmallImage";
import AccessoriesImage from "../AccessoriesImage/AccessoriesImage";
import ConfigRow from "./ConfigRow";

export interface ElineConfigProps {
    size: ElineConfigSize,
    selection: string,
    config: heaterConfigType,
    changeConfig: Dispatch<SetStateAction<heaterConfigType>>,
    setDataForEmail: () => void
}

const ElineConfig = ({ size, selection, config, changeConfig, setDataForEmail }: ElineConfigProps) => {
    const isBigEline = size === SIZES.big

    const configData = isBigEline
        ? tabs.tab3.eline_big
        : exchangersWithoutBSPP.some(el => selection === el)
            ? tabs.tab3.eline_small
                .map((item, index) => index === 2
                    ? {...item, rows: item.rows.filter(elem => elem.id !== "accesories_bspp")}
                    : item
                )
            : tabs.tab3.eline_small

    const renderedConfiguration = isBigEline ? (
        <>
            {configData.map(section => {
                return (
                    <div key={section.id} className={styles.results_tab3_container_elineBig}>
                        <ConfigRow section={section} config={config} changeConfig={changeConfig}/>
                        <div className={styles.results_tab3_inlet_img}>
                            <img
                                src={config[section.id].img ?? '/assets/img/error.png'}
                                alt="exchanger" width={128} height={158}/>
                        </div>

                    </div>
                )
            })}
        </>
    ) : (
        <div className={styles.results_tab3_container_elineSmall}>

            {/* configuration */}
            <div className={styles.results_tab3_left_elineSmall}>
                {configData.map(section => {
                    return (
                        <ConfigRow key={section.id} section={section} config={config} changeConfig={changeConfig}/>
                    )
                })}
            </div>

            {/* images */}

            <div className={styles.results_tab3_right_elineSmall}>
                <ElineSmallImage
                    eline_small_inlet={config.eline_small_inlet}
                    eline_small_outlet={config.eline_small_outlet}
                />
                <AccessoriesImage
                    img={config.eline_small_accesories.img as string}/>
            </div>
        </div>
    )

     return (
        <>
            {renderedConfiguration}
            <button className={styles.results_tab3_btn} onClick={setDataForEmail}>
                {translate('GET A QUOTATION')}
            </button>
        </>
    );
};

export default ElineConfig;
