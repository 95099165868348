import React, {useEffect, useState} from 'react';
import "../../styles/common.css"
import styles from "../Results/Results.module.css";
import {translate} from "../../utils/translate";
import {getQuotationHandler} from "../../utils/getQuotationHandler";
import SendMailModal from "../Modals/SendMailModal";
import {SizeType, UseConfiguration} from "../../utils/hooks/UseConfiguration";
import {ElineConfigSize, InputValuesType, ResultType, StoredUnitsType} from "../../utils/types";
import ElineConfig from "../ElineConfig";
import {useModalConfig} from "../../utils/hooks/useModalConfig";
import api from "../../api";
import {toast} from "react-toastify";
import {UseResultTabs} from "../../utils/hooks/UseResultTabs";


interface ConfigurationProps {
    results: ResultType,
    inputValues: InputValuesType,
    storedUnits: StoredUnitsType,
}

const Configuration = ({ results, inputValues, storedUnits }: ConfigurationProps) => {
    const { heat_exchanger_size, heat_exchanger_selection } = results.solution;
    const { config, changeConfig } = UseConfiguration(heat_exchanger_size as SizeType)

    const [mailContent, setMailContent] = useState({subject: '', bodyToSend: ''});
    const [productWGIndex, setProductWGIndex] = useState('');
    const { ref, isShown, setIsShown, isModalClosing, closeSoftly } = useModalConfig(false);


    const setDataForEmail = (): void => {
        setMailContent({...getQuotationHandler(results.solution, inputValues, config, storedUnits, productWGIndex)})
        setIsShown(true)
    }

    const { isSolutionFound, calculatedSolutionFound, noSolution } = UseResultTabs(results.solution)

    useEffect(() => {
        if (isSolutionFound) {
            const keys: { [id: string]: string } = {};
            Object.keys(config).forEach(key => keys[key] = config[key].id)

            api.getProductIndex({
                model: heat_exchanger_selection,
                ...keys
            })
              .then(res => setProductWGIndex(res.data.index))
              .catch(error => {
                  setProductWGIndex('')
                  toast.error(error.message)
              });

            return;
        }

        setProductWGIndex('')
    }, [results, config]);


    return (
        <>
            <div className={styles.results_tab_container}>

                {isSolutionFound &&
                    <ElineConfig
                        size={heat_exchanger_size as ElineConfigSize}
                        selection={heat_exchanger_selection}
                        config={config}
                        changeConfig={changeConfig}
                        setDataForEmail={setDataForEmail}/>
                }

                {/* RESULT: WITH CALCULATIONS, NO SOLUTION */}
                {calculatedSolutionFound &&
                    <>
                        <p className={styles.results_no_result}>{translate('Heat exchanger not found. Please contact us to find a solution.')}</p>
                        <button className="est_button" onClick={setDataForEmail}>
                            {translate('CONTACT AIC TEAM')}
                        </button>
                    </>
                }


                {/* RESULT: NO SOLUTION, NO CALCULATIONS */}
                {noSolution &&
                    <p className={styles.results_no_result}>
                        {translate('Insufficient data entered to match an economizer. Please fill all required fields correctly')}
                    </p>
                }
            </div>

            {isShown &&
                <SendMailModal
                    reference={ref}
                    mailContent={mailContent}
                    closeModal={closeSoftly}
                    isClosing={isModalClosing}
                />
            }
        </>
    );
};

export default Configuration;
