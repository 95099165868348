import {sidesData} from "./dataForms";
import {ResultType} from "./types";
import {translate} from "./translate";

export const sliderLabels = ['hot_boiler_gas_input', 'hot_mass_flow_of_medium', 'cold_mass_flow_of_medium']

export const SIZES = {
    'big': 'ELINE-BIG',
    'small': 'ELINE-SMALL'
}

export const defaultUnits = {
    'ColdDropPressure': "kPa",
    'Flow' : "kg/h",
    'Fouling': "m²K/W",
    'Power': "kW",
    'Pressure': "Pa",
    'Temperature': "°C",
    'Oversize': '%'
}

export const mediumsLabels = {
    'hot_medium': {
        "BIOMASS": translate("Biomass"),
        "FLUE_GAS": translate("Flue gas"),
        "HOT_AIR": translate("Hot air"),
    },
    'cold_medium': {
        "ETHYLENE_GLYCOL_20": translate("Ethylene glycol 20%"),
        "ETHYLENE_GLYCOL_30": translate("Ethylene glycol 30%"),
        "ETHYLENE_GLYCOL_40": translate("Ethylene glycol 40%"),
        "ETHYLENE_GLYCOL_50": translate("Ethylene glycol 50%"),
        "PROPYLENE_GLYCOL_20": translate("Propylene glycol 20%"),
        "PROPYLENE_GLYCOL_30": translate("Propylene glycol 30%"),
        "PROPYLENE_GLYCOL_40": translate("Propylene glycol 40%"),
        "PROPYLENE_GLYCOL_50": translate("Propylene glycol 50%"),
        "THERMINOL_66": translate("Therminol 66"),
        "WATER": translate("Water"),
    }
}

export const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const exchangersWithoutBSPP = ['E-Line E12.2'];

export const defaultFormValues = sidesData
    .map(elem => ({[elem.id]: elem.default_value}))
    .reduce((prev, curr) => Object.assign(curr, prev), {})

export const defaultResults = {
    solution: {hot_side: {}, cold_side: {}},
    form: {}
} as ResultType;
