import {ColdMediums, HotMediums, TemperatureLimits, Temperatures} from "../types";
import {AnyObject, TestContext, TestFunction} from "yup";

function validateTemperature(mediumValue: 'hot_medium', unitValue: string, limits: Record<HotMediums, Record<'temperature', TemperatureLimits>>): TestFunction<string | undefined, AnyObject>;
function validateTemperature(mediumValue: 'cold_medium', unitValue: string, limits: Record<ColdMediums, Record<'temperature', TemperatureLimits>>): TestFunction<string | undefined, AnyObject>;

function validateTemperature(mediumValue: any, unitValue: any, limits: any): any {

    return (value: string, { parent, createError }: TestContext) => {
        const {
            [mediumValue]: medium,
            [unitValue]: unit,
            hot_inlet_temperature_of_medium: inletHotT,
            hot_outlet_temperature_of_medium: outletHotT,
            cold_inlet_temperature_of_medium: inletColdT,
            cold_outlet_temperature_of_medium: outletColdT,
        } = parent;

        if (!value) return true;

        const limitObj = limits[medium].temperature[unit as Temperatures]

        if (Number(value) < limitObj.min) return createError({
            message: `Must be greater than or equal ${limitObj.min}${unit}`
        })

        if (Number(value) > limitObj.max) return createError({
            message: `Must be less than or equal ${limitObj.max}${unit}`
        })

        // hot_inlet_temperature_of_medium > hot_outlet_temperature_of_medium
        if (outletHotT && Number(outletHotT) < limitObj.max && Number(inletHotT) <= Number(outletHotT)) return createError({
            path: 'hot_inlet_temperature_of_medium',
            message: `Must be greater than ${outletHotT} (HOT Outlet temperature of medium)`
        })

        // hot_inlet_temperature_of_medium > cold_outlet_temperature_of_medium
        if (outletColdT && Number(outletColdT) < limitObj.max && Number(inletHotT) <= Number(outletColdT) ) return createError({
            path: 'hot_inlet_temperature_of_medium',
            message: `Must be greater than ${outletColdT} (COLD Outlet temperature of medium)`
        })

        // hot_outlet_temperature_of_medium < hot_inlet_temperature_of_medium
        if (inletHotT && Number(inletHotT) > limitObj.min && Number(outletHotT) >= Number(inletHotT)) return createError({
            path: 'hot_outlet_temperature_of_medium',
            message: `Must be less than ${inletHotT} (HOT Inlet temperature of medium)`
        })

        // hot_outlet_temperature_of_medium > cold_inlet_temperature_of_medium
        if (inletColdT && Number(inletColdT) < limitObj.max && Number(outletHotT) <= Number(inletColdT)) return createError({
            path: 'hot_outlet_temperature_of_medium',
            message: `Must be greater than ${inletColdT} (COLD Inlet temperature of medium)`
        })

        // cold_inlet_temperature_of_medium < cold_outlet_temperature_of_medium
        if (outletColdT && Number(outletColdT) > limitObj.min && Number(inletColdT) >= Number(outletColdT)) return createError({
            path: 'cold_inlet_temperature_of_medium',
            message: `Must be less than ${outletColdT} (COLD Outlet temperature of medium)`
        })

        // cold_inlet_temperature_of_medium < hot_outlet_temperature_of_medium
        if (outletHotT && Number(outletColdT) > limitObj.min && Number(inletColdT) >= Number(outletHotT)) return createError({
            path: 'cold_inlet_temperature_of_medium',
            message: `Must be less than ${outletHotT} (HOT Outlet temperature of medium)`
        })

        // cold_outlet_temperature_of_medium > cold_inlet_temperature_of_medium
        if (inletColdT && Number(inletColdT) < limitObj.max && Number(outletColdT) <= Number(inletColdT)) return createError({
            path: 'cold_outlet_temperature_of_medium',
            message: `Must be greater than ${inletColdT} (COLD Inlet temperature of medium)`
        })

        // cold_outlet_temperature_of_medium < hot_inlet_temperature_of_medium
        if (inletHotT && Number(inletHotT) > limitObj.min && Number(outletColdT) >= Number(inletHotT)) return createError({
            path: 'cold_outlet_temperature_of_medium',
            message: `Must be less than ${inletHotT} (HOT Inlet temperature of medium)`
        })

        return true;
    }
}

export default validateTemperature;
