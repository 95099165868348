import {AnyObject, TestContext, TestFunction} from "yup";

export const validateSliders: TestFunction<string | undefined, AnyObject> = (value: string | undefined, { parent }: TestContext) => {
        const { hot_boiler_gas_input, cold_mass_flow_of_medium, hot_mass_flow_of_medium } = parent;
        if (hot_mass_flow_of_medium || hot_boiler_gas_input || cold_mass_flow_of_medium) {
            return (
                (hot_mass_flow_of_medium && !hot_boiler_gas_input && !cold_mass_flow_of_medium) ||
                (!hot_mass_flow_of_medium && hot_boiler_gas_input && !cold_mass_flow_of_medium) ||
                (!hot_mass_flow_of_medium && !hot_boiler_gas_input && cold_mass_flow_of_medium)
            );
        }
        return true;
}
